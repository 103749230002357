export default function () {
  const { $api } = useNuxtApp()

  interface Model {
    id: number
    name: string
  }

  const models = ref<Model[]>([])
  const isLoading = ref(false)

  const getModelsByManufacturer = async (manufacturerId: number) => {
    isLoading.value = true
    const res = await $api(`/models/manufacturer/${manufacturerId}`)
    models.value = res.models as Model[]
    isLoading.value = false
  }

  return {
    getModelsByManufacturer,
    models,
    isLoading,
  }
}
