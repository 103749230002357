export default function () {
  const { $api } = useNuxtApp()

  interface Year {
    id: number
    name: string
  }

  const years = ref<Year[]>([])
  const isLoading = ref(false)

  const getYearsByManufactureAndModel = async ({
    service_name,
    manufacturer_id,
    model_id,
  }: {
    service_name: string
    manufacturer_id: number
    model_id: number
  }) => {
    isLoading.value = true

    const res = await $api(
      `/years?service_name=${service_name}&manufacturer_id=${manufacturer_id}&model_id=${model_id}`
    )
    years.value = res.years as Year[]
    isLoading.value = false
  }

  return {
    isLoading,
    years,
    getYearsByManufactureAndModel,
  }
}
