export default function () {
  const { $api } = useNuxtApp()



  interface WarrantyList {
    id: number
    name: string
    name_arabic: string
  }

  interface WarrantyCoverageList {
    id: number
    title_en: string
    title_ar: string
    inspection_price: number
    description: string
    description_ar: string
  }

  const warrantyList = ref<WarrantyList[]>([])
  const warrantyCoverageList = ref<WarrantyCoverageList[]>([])


  // const isLoading = ref(false)



  const getWarranty = async () => {
    // isLoading.value = true
    const res = await $api(`/warranty-request-branches`)
    warrantyList.value = res.app_services as WarrantyList[]
    // isLoading.value = false
  }

  const getWarrantyCoverage = async () => {
    // isLoading.value = true
    const res = await $api(`/warranty/types`)
    warrantyCoverageList.value = res.offlineWarranty as WarrantyCoverageList[]
    // isLoading.value = false
  }

  return {
    getWarranty,
    getWarrantyCoverage,
    warrantyList,
    warrantyCoverageList,
    // isLoading,
  }
}
