export default function () {
  const { $api } = useNuxtApp()

  interface Manufacturer {
    id: number
    name: string
    name_arabic: string
    tags: string
    make_logo: string
    elm_id: string
    elm_title: string
    sort: number
    stock_life_time: null
    trend_price_percentage: null
    vendor_make_id: string
    vendor_title: string
    vendor_title_arabic: string
  }


  const manufacturers = ref<Manufacturer[]>([])

  const isLoading = ref(false)

  const getAllManufacturers = async (
    {
      priced_models,
      all_years,
    }: {
      priced_models: number
      all_years: number
    } = { priced_models: 1, all_years: 0 }
  ) => {
    isLoading.value = true
    const res = await $api(`/manufacturers?priced_models=${priced_models}&all_years=${all_years}`)
    manufacturers.value = res.manufacturers as Manufacturer[]
    isLoading.value = false
  }



  return {
    getAllManufacturers,
    manufacturers,
    isLoading,
  }
}
