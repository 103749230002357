<script lang="ts" setup></script>

<template>
  <main class="theme-app">
    <slot />
  </main>
</template>

<style lang="scss">
@use '@/pages/landing/mobile-app-1/assets/scss/theme.scss' as *;
</style>
