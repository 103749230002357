import { defineStore } from "pinia";
import { ref } from "vue";

export const useLanguageStore = defineStore("language", () => {
  const selectedLanguage = ref(
    localStorage.getItem("selectedLanguage") || "ar"
  );
  const isLoading = ref(false);
  const cachedTranslations = ref<Record<string, any>>({});

  // Function to change language
  const changeLanguage = async (lang: string) => {
    // Immediately update selectedLanguage in the store and localStorage
    selectedLanguage.value = lang;
    localStorage.setItem("selectedLanguage", lang);

    // Check for cached translations
    if (cachedTranslations.value[lang]) {
      return cachedTranslations.value[lang]; // Return cached translations
    }

    isLoading.value = true;

    try {
      const response = await fetch(
        `https://cms.cartrust.sa/api/language-labels?locale=${lang}&pagination[pageSize]=1000`,
        { method: "GET", headers: { "Content-Type": "application/json" } }
      );
      const data = await response.json();
      if (!response.ok) throw new Error(`Server error: ${response.status}`);

      if (data?.data) {
        const translations = data.data.reduce((acc: any, item: any) => {
          acc[item.label_key] = item.text;
          return acc;
        }, {});

        cachedTranslations.value[lang] = translations;
        return translations;
      }
    } catch (error) {
      console.error("Error fetching language labels:", error);
    } finally {
      isLoading.value = false;
    }
    return null;
  };

  return { selectedLanguage, isLoading, cachedTranslations, changeLanguage };
});
