
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as _91_46_46_46slug_93RDTJWBz6I4Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/[...slug].vue?macro=true";
import { default as About1HeroQUDlGpRgxuMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/About1Hero.vue?macro=true";
import { default as AwardsjRsj4DW4H8Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/Awards.vue?macro=true";
import { default as BenefitskVJ10jXDh5Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/Benefits.vue?macro=true";
import { default as Contactq2b8jn6kUYMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/Contact.vue?macro=true";
import { default as SocialNetworkqQRgiAqcH5Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/SocialNetwork.vue?macro=true";
import { default as TeamslmaP1W9YNMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/Team.vue?macro=true";
import { default as TeamCardi1R9fZbJbMMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/TeamCard.vue?macro=true";
import { default as TestimonialWvLpHdbOrXMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/Testimonial.vue?macro=true";
import { default as TestimonialCardo2GvJGKShKMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/TestimonialCard.vue?macro=true";
import { default as VideoReelY5wmmzXjYhMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/VideoReel.vue?macro=true";
import { default as dataKi6AGlCxABMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/data.ts?macro=true";
import { default as indexC7lJSFUqWQMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/index.vue?macro=true";
import { default as indexHyvHwFqkwcMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/branches/index.vue?macro=true";
import { default as index8uMWiaPHC2Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cancel/index.vue?macro=true";
import { default as index3HUeaTum7xMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/career/index.vue?macro=true";
import { default as FaqslgyZB8xqQdMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Faqs.vue?macro=true";
import { default as FeaturesglcAXYFsaNMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Features.vue?macro=true";
import { default as OpportunitiesmAy7ovvNHIMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Opportunities.vue?macro=true";
import { default as PlanComparisonPUSnlWlITqMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/PlanComparison.vue?macro=true";
import { default as PricingJmCJoZiUfxMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Pricing.vue?macro=true";
import { default as Saas3HerokTg0RpPwxBMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Saas3Hero.vue?macro=true";
import { default as TestimonialzVFms86If2Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Testimonial.vue?macro=true";
import { default as TestimonialCardvtJPhjsHVhMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/TestimonialCard.vue?macro=true";
import { default as WorkNqpLdazYueMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Work.vue?macro=true";
import { default as datagXWV9yrX6OMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/data.ts?macro=true";
import { default as indexT01z0P01H1Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/index.vue?macro=true";
import { default as indexgpOtpHs9NfMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/checkout/index.vue?macro=true";
import { default as Contacts1FooteruFH9ofTMdHMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/contact/components/Contacts1Footer.vue?macro=true";
import { default as Contacts1HeroGsgNebMwS5Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/contact/components/Contacts1Hero.vue?macro=true";
import { default as DetailsEZcW0DUtaBMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/contact/components/Details.vue?macro=true";
import { default as Faqs1H40WnSjRRMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/contact/components/Faqs.vue?macro=true";
import { default as dataVvZz6TX0wLMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/contact/data.ts?macro=true";
import { default as indexWSxAutHKlQMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/contact/index.vue?macro=true";
import { default as index3DSExDRhtfMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/coupons/index.vue?macro=true";
import { default as indexBhK8qzFTYSMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/index.vue?macro=true";
import { default as inspectionsax7r6HsLuFMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/inspections.vue?macro=true";
import { default as indexNe0FXb7uXoMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/profile/index.vue?macro=true";
import { default as transaction40m0j1fqDaMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/transaction.vue?macro=true";
import { default as index04Htf83BVQMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/user-scan-list/index.vue?macro=true";
import { default as vehiclesf59Gv3RCTNMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/vehicles.vue?macro=true";
import { default as wallethw7MIK1VJxMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/wallet.vue?macro=true";
import { default as warrantiesqXEdXgE6EEMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/warranties.vue?macro=true";
import { default as ContentIbmj4e8jjtMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/data-products-disclaimer/components/Content.vue?macro=true";
import { default as indexk4Rrq9MgolMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/data-products-disclaimer/index.vue?macro=true";
import { default as BrandsE3HDFtp4gxMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Brands.vue?macro=true";
import { default as CarSearchBoxeFOEfAt6eLMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/CarSearchBox.vue?macro=true";
import { default as ContactjEirgnPWmiMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Contact.vue?macro=true";
import { default as CovidBannerUUjHs31wYeMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/CovidBanner.vue?macro=true";
import { default as FeaturewO4RJaNtnTMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Feature.vue?macro=true";
import { default as FinancialFooterTHGbsr5fceMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/FinancialFooter.vue?macro=true";
import { default as FinancialHeroftb52wvPx5Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/FinancialHero.vue?macro=true";
import { default as IndustrieSwipern1uaIjJJu7Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/IndustrieSwiper.vue?macro=true";
import { default as NewsCardKVDYlwiG7qMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/NewsCard.vue?macro=true";
import { default as NewsSwiper5vdRF5rvHwMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/NewsSwiper.vue?macro=true";
import { default as PricingqVlObOwvYjMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Pricing.vue?macro=true";
import { default as PurchaseLRKDh9wMiqMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Purchase.vue?macro=true";
import { default as ServicesiWv1gSmE5jMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Services.vue?macro=true";
import { default as Sponsorsxnb4i7JQVkMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Sponsors.vue?macro=true";
import { default as TestimonialEIlILcU2cyMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Testimonial.vue?macro=true";
import { default as TestimonialCardwhqGROy9xRMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/TestimonialCard.vue?macro=true";
import { default as dataot4kZ4WkPkMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/data.ts?macro=true";
import { default as indexlJ2D6PeADRMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/index.vue?macro=true";
import { default as indexqujjdqHaiLMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluations/[id]/index.vue?macro=true";
import { default as feedbacks3goOTqpDwMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluations/Components/feedback.vue?macro=true";
import { default as ReEvaluation2iuxCYIpg1Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluations/Components/ReEvaluation.vue?macro=true";
import { default as indexhhLA8wrlkdMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluations/index.vue?macro=true";
import { default as indexGVsyr8TbR2Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/fail/index.vue?macro=true";
import { default as Contacts1HerotGQjqT6f3JMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/faq/components/Contacts1Hero.vue?macro=true";
import { default as DetailscEZrsjjEeVMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/faq/components/Details.vue?macro=true";
import { default as FaqsbZdOZoIFgRMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/faq/components/Faqs.vue?macro=true";
import { default as datafjik97vmKCMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/faq/data.ts?macro=true";
import { default as indexMt4hGxByKEMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/faq/index.vue?macro=true";
import { default as HeroSectionsqJpDPFKaSMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/help-me-by-a-car/components/HeroSection.vue?macro=true";
import { default as SearchCarKpTxLig0ffMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/help-me-by-a-car/components/SearchCar.vue?macro=true";
import { default as SpecificationstGB3eAUOheMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/help-me-by-a-car/components/Specifications.vue?macro=true";
import { default as indexNHSSNO33vhMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/help-me-by-a-car/index.vue?macro=true";
import { default as ContentZpRitQiirdMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/impartiality-policy/components/Content.vue?macro=true";
import { default as indexmqvX87d7e1Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/impartiality-policy/index.vue?macro=true";
import { default as index0vTwMH94WEMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/index.vue?macro=true";
import { default as CarSearchBoxKuC15rqcXtMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/CarSearchBox.vue?macro=true";
import { default as ComparisonSlider86QyZkqDpfMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/ComparisonSlider.vue?macro=true";
import { default as CtaCGNn1GpHCbMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Cta.vue?macro=true";
import { default as FeaturesvCKIAd5VQfMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Features.vue?macro=true";
import { default as Features2F14G9JUm2OMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Features2.vue?macro=true";
import { default as InspectionTypesDDNF2YogiNMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/InspectionTypes.vue?macro=true";
import { default as Integrationsm28JwWStlQMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Integrations.vue?macro=true";
import { default as Saas1FooteruuA2cfDwl4Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Saas1Footer.vue?macro=true";
import { default as Saas1Hero7mWRvceOT5Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Saas1Hero.vue?macro=true";
import { default as Testimonialk1VfMWzHCxMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Testimonial.vue?macro=true";
import { default as TestimonialCardpQsyFpskDcMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/TestimonialCard.vue?macro=true";
import { default as UseCaseZJ4hrvx3TjMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/UseCase.vue?macro=true";
import { default as WorkolTVOojAXiMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Work.vue?macro=true";
import { default as datagwX0SJwdPOMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/data.ts?macro=true";
import { default as indexIy1AtPIKcIMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/index.vue?macro=true";
import { default as indexeS6vly4KAPMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspections/[id]/index.vue?macro=true";
import { default as indexGuB7zTzvlDMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspections/index.vue?macro=true";
import { default as image504kY5oLtjMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/assets/data/image.ts?macro=true";
import { default as BrandsvsGSVmB1fuMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/Brands.vue?macro=true";
import { default as Cta2UWHUiCHMFMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/Cta.vue?macro=true";
import { default as FaqsosVxWYfvpmMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/Faqs.vue?macro=true";
import { default as Features1bCXGEbvQ7DMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/Features1.vue?macro=true";
import { default as Features26eU8dIInJNMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/Features2.vue?macro=true";
import { default as MobileApp1FooterUJaUbWV3AgMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/MobileApp1Footer.vue?macro=true";
import { default as MobileApp1HerobXWsvZcayvMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/MobileApp1Hero.vue?macro=true";
import { default as ProcessSwiperKJxRFJQUlmMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/ProcessSwiper.vue?macro=true";
import { default as TestimonialaQSbcI0K9rMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/Testimonial.vue?macro=true";
import { default as TestimonialCard46VVD0tTjzMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/TestimonialCard.vue?macro=true";
import { default as dataqTFqpmeMWSMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/data.ts?macro=true";
import { default as indexlzj2oa5G7iMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/index.vue?macro=true";
import { default as AnchorNavigationQfRrFkjN2rMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/AnchorNavigation.vue?macro=true";
import { default as BreadcrumbDKPVXwGo13Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/Breadcrumb.vue?macro=true";
import { default as CustomSyntax7dk4WhG0krMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/CustomSyntax.vue?macro=true";
import { default as Jarallax9ZX15W8HKmMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/Jarallax.vue?macro=true";
import { default as LightGalleryrWOh4Nmx19Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/LightGallery.vue?macro=true";
import { default as LoadingSpinnerk0mmBLzT12Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/LoadingSpinner.vue?macro=true";
import { default as ParallaxLnCgEdyAykMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/Parallax.vue?macro=true";
import { default as PasswordInputoMRvEkRhv6Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/PasswordInput.vue?macro=true";
import { default as RellaxdGFPQSXwEXMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/Rellax.vue?macro=true";
import { default as VanillaTilthplh39HAZAMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/VanillaTilt.vue?macro=true";
import { default as VideoPopupV6JRaCgoRXMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/VideoPopup.vue?macro=true";
import { default as Contact4wdtP2xNWxMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/Contact.vue?macro=true";
import { default as CtaLcn1udSS76Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/Cta.vue?macro=true";
import { default as FeatureH0mCzE6fFrMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/Feature.vue?macro=true";
import { default as FeatureCardT9epBAAvfKMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/FeatureCard.vue?macro=true";
import { default as MedicalFooterii4CNIVJyVMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/MedicalFooter.vue?macro=true";
import { default as MedicalHerovJI5qvvNfXMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/MedicalHero.vue?macro=true";
import { default as NewsilndKsTtlnMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/News.vue?macro=true";
import { default as NewsCardovlQINSJEeMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/NewsCard.vue?macro=true";
import { default as ServiceCardENxhE2KNSHMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/ServiceCard.vue?macro=true";
import { default as ServicesCLAXgYuXczMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/Services.vue?macro=true";
import { default as Team5TkSkepv8CMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/Team.vue?macro=true";
import { default as TeamCard5QwC4NPYq7Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/TeamCard.vue?macro=true";
import { default as Testimonialk3vePHPIHMMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/Testimonial.vue?macro=true";
import { default as TestimonialCardemUH1LWOKjMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/TestimonialCard.vue?macro=true";
import { default as VideoReelENFuf1alXBMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/VideoReel.vue?macro=true";
import { default as datayZw48NNMY7Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/data.ts?macro=true";
import { default as indexvIP8hZO7R3Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/index.vue?macro=true";
import { default as ArticleCardq0rYzkokDqMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/ArticleCard.vue?macro=true";
import { default as ArticlesBTopPWYMxhMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/Articles.vue?macro=true";
import { default as CommentKgFEpRkFZ2Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/Comment.vue?macro=true";
import { default as CommentFormOHyBOwoFfnMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/CommentForm.vue?macro=true";
import { default as Jarallax2KtPsIyATLMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/Jarallax.vue?macro=true";
import { default as LightGalleryO80oNX9KetMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/LightGallery.vue?macro=true";
import { default as SinglePostHeromluK9JdGXUMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/SinglePostHero.vue?macro=true";
import { default as dataDAxZt1dqXUMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/data.ts?macro=true";
import { default as indexJ4m82KR09XMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/index.vue?macro=true";
import { default as BlogCardJ5Q2ZB7eNaMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/components/BlogCard.vue?macro=true";
import { default as ContentYqF62bXfDYMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/components/Content.vue?macro=true";
import { default as Paginationc3taJjoAoAMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/components/Pagination.vue?macro=true";
import { default as dataEtkNwyrYeGMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/data.ts?macro=true";
import { default as index58NQmxYdQ7Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/index.vue?macro=true";
import { default as indexGp6cIprigZMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/our-clients/index.vue?macro=true";
import { default as indexexGx1n1OlgMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/partners/index.vue?macro=true";
import { default as indexv5wfaEd2YYMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/payment/redirect/index.vue?macro=true";
import { default as ContentHApWkEqOs6Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/privacy-policy/components/Content.vue?macro=true";
import { default as indexp4bAzB3xz3Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/privacy-policy/index.vue?macro=true";
import { default as indexmY4KGrL1poMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/sales-agent-form/index.vue?macro=true";
import { default as indexv1tEafQWP2Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/success/index.vue?macro=true";
import { default as Content4ALuKc8WuvMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/terms-and-condition/components/Content.vue?macro=true";
import { default as indexnjKZ45cuOWMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/terms-and-condition/index.vue?macro=true";
import { default as indexOGyJkDqnAZMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/trailer-inspection-and-half-trailers/index.vue?macro=true";
import { default as indexxYxpfWDFg7Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/trim/index.vue?macro=true";
import { default as indexK7uR2g3gu8Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/vin-decoder/index.vue?macro=true";
import { default as indexR4G8fXNmh6Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranties/index.vue?macro=true";
import { default as PricingA3ezM6ZkH2Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty-form-autohub/components/Pricing.vue?macro=true";
import { default as PricingForMobileRpf36ry1m5Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty-form-autohub/components/PricingForMobile.vue?macro=true";
import { default as Saas3HeroMuXqfGz6IuMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty-form-autohub/components/Saas3Hero.vue?macro=true";
import { default as WarrantyFormdLt6zuTGNvMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty-form-autohub/components/WarrantyForm.vue?macro=true";
import { default as indexlf463doIGXMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty-form-autohub/index.vue?macro=true";
import { default as index5ZvmeyB6NuMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty-form/index.vue?macro=true";
import { default as CarSearchBox3LQxjYJD0VMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/CarSearchBox.vue?macro=true";
import { default as Pricing2uNtK6buSXMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/Pricing.vue?macro=true";
import { default as PricingForMobilec5GhldnCqlMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/PricingForMobile.vue?macro=true";
import { default as Saas3HeropxLGvZOQtaMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/Saas3Hero.vue?macro=true";
import { default as Testimonial9RhcLsPTrBMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/Testimonial.vue?macro=true";
import { default as TestimonialCardZVlpyMNdYEMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/TestimonialCard.vue?macro=true";
import { default as WarrantyFeatureq5x3xUaXsUMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/WarrantyFeature.vue?macro=true";
import { default as Work1DnjVnLR8lMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/Work.vue?macro=true";
import { default as datasEnBISx73UMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/data.ts?macro=true";
import { default as indexwXkA4sWPgsMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93RDTJWBz6I4Meta || {},
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/[...slug].vue")
  },
  {
    name: "about-components-About1Hero",
    path: "/about/components/About1Hero",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/About1Hero.vue")
  },
  {
    name: "about-components-Awards",
    path: "/about/components/Awards",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/Awards.vue")
  },
  {
    name: "about-components-Benefits",
    path: "/about/components/Benefits",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/Benefits.vue")
  },
  {
    name: "about-components-Contact",
    path: "/about/components/Contact",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/Contact.vue")
  },
  {
    name: "about-components-SocialNetwork",
    path: "/about/components/SocialNetwork",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/SocialNetwork.vue")
  },
  {
    name: "about-components-Team",
    path: "/about/components/Team",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/Team.vue")
  },
  {
    name: "about-components-TeamCard",
    path: "/about/components/TeamCard",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/TeamCard.vue")
  },
  {
    name: "about-components-Testimonial",
    path: "/about/components/Testimonial",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/Testimonial.vue")
  },
  {
    name: "about-components-TestimonialCard",
    path: "/about/components/TestimonialCard",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/TestimonialCard.vue")
  },
  {
    name: "about-components-VideoReel",
    path: "/about/components/VideoReel",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/VideoReel.vue")
  },
  {
    name: "about-data",
    path: "/about/data",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/data.ts")
  },
  {
    name: "about",
    path: "/about",
    meta: indexC7lJSFUqWQMeta || {},
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/index.vue")
  },
  {
    name: "branches",
    path: "/branches",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/branches/index.vue")
  },
  {
    name: "cancel",
    path: "/cancel",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cancel/index.vue")
  },
  {
    name: "career",
    path: "/career",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/career/index.vue")
  },
  {
    name: "cartrust-agent-components-Faqs",
    path: "/cartrust-agent/components/Faqs",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Faqs.vue")
  },
  {
    name: "cartrust-agent-components-Features",
    path: "/cartrust-agent/components/Features",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Features.vue")
  },
  {
    name: "cartrust-agent-components-Opportunities",
    path: "/cartrust-agent/components/Opportunities",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Opportunities.vue")
  },
  {
    name: "cartrust-agent-components-PlanComparison",
    path: "/cartrust-agent/components/PlanComparison",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/PlanComparison.vue")
  },
  {
    name: "cartrust-agent-components-Pricing",
    path: "/cartrust-agent/components/Pricing",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Pricing.vue")
  },
  {
    name: "cartrust-agent-components-Saas3Hero",
    path: "/cartrust-agent/components/Saas3Hero",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Saas3Hero.vue")
  },
  {
    name: "cartrust-agent-components-Testimonial",
    path: "/cartrust-agent/components/Testimonial",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Testimonial.vue")
  },
  {
    name: "cartrust-agent-components-TestimonialCard",
    path: "/cartrust-agent/components/TestimonialCard",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/TestimonialCard.vue")
  },
  {
    name: "cartrust-agent-components-Work",
    path: "/cartrust-agent/components/Work",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Work.vue")
  },
  {
    name: "cartrust-agent-data",
    path: "/cartrust-agent/data",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/data.ts")
  },
  {
    name: "cartrust-agent",
    path: "/cartrust-agent",
    meta: indexT01z0P01H1Meta || {},
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/index.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/checkout/index.vue")
  },
  {
    name: "contact-components-Contacts1Footer",
    path: "/contact/components/Contacts1Footer",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/contact/components/Contacts1Footer.vue")
  },
  {
    name: "contact-components-Contacts1Hero",
    path: "/contact/components/Contacts1Hero",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/contact/components/Contacts1Hero.vue")
  },
  {
    name: "contact-components-Details",
    path: "/contact/components/Details",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/contact/components/Details.vue")
  },
  {
    name: "contact-components-Faqs",
    path: "/contact/components/Faqs",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/contact/components/Faqs.vue")
  },
  {
    name: "contact-data",
    path: "/contact/data",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/contact/data.ts")
  },
  {
    name: "contact",
    path: "/contact",
    meta: indexWSxAutHKlQMeta || {},
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/contact/index.vue")
  },
  {
    name: "dashboard-coupons",
    path: "/dashboard/coupons",
    meta: index3DSExDRhtfMeta || {},
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/coupons/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexBhK8qzFTYSMeta || {},
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-inspections",
    path: "/dashboard/inspections",
    meta: inspectionsax7r6HsLuFMeta || {},
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/inspections.vue")
  },
  {
    name: "dashboard-profile",
    path: "/dashboard/profile",
    meta: indexNe0FXb7uXoMeta || {},
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/profile/index.vue")
  },
  {
    name: "dashboard-transaction",
    path: "/dashboard/transaction",
    meta: transaction40m0j1fqDaMeta || {},
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/transaction.vue")
  },
  {
    name: "dashboard-user-scan-list",
    path: "/dashboard/user-scan-list",
    meta: index04Htf83BVQMeta || {},
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/user-scan-list/index.vue")
  },
  {
    name: "dashboard-vehicles",
    path: "/dashboard/vehicles",
    meta: vehiclesf59Gv3RCTNMeta || {},
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/vehicles.vue")
  },
  {
    name: "dashboard-wallet",
    path: "/dashboard/wallet",
    meta: wallethw7MIK1VJxMeta || {},
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/wallet.vue")
  },
  {
    name: "dashboard-warranties",
    path: "/dashboard/warranties",
    meta: warrantiesqXEdXgE6EEMeta || {},
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/warranties.vue")
  },
  {
    name: "data-products-disclaimer-components-Content",
    path: "/data-products-disclaimer/components/Content",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/data-products-disclaimer/components/Content.vue")
  },
  {
    name: "data-products-disclaimer",
    path: "/data-products-disclaimer",
    meta: indexk4Rrq9MgolMeta || {},
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/data-products-disclaimer/index.vue")
  },
  {
    name: "evaluation-components-Brands",
    path: "/evaluation/components/Brands",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Brands.vue")
  },
  {
    name: "evaluation-components-CarSearchBox",
    path: "/evaluation/components/CarSearchBox",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/CarSearchBox.vue")
  },
  {
    name: "evaluation-components-Contact",
    path: "/evaluation/components/Contact",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Contact.vue")
  },
  {
    name: "evaluation-components-CovidBanner",
    path: "/evaluation/components/CovidBanner",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/CovidBanner.vue")
  },
  {
    name: "evaluation-components-Feature",
    path: "/evaluation/components/Feature",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Feature.vue")
  },
  {
    name: "evaluation-components-FinancialFooter",
    path: "/evaluation/components/FinancialFooter",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/FinancialFooter.vue")
  },
  {
    name: "evaluation-components-FinancialHero",
    path: "/evaluation/components/FinancialHero",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/FinancialHero.vue")
  },
  {
    name: "evaluation-components-IndustrieSwiper",
    path: "/evaluation/components/IndustrieSwiper",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/IndustrieSwiper.vue")
  },
  {
    name: "evaluation-components-NewsCard",
    path: "/evaluation/components/NewsCard",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/NewsCard.vue")
  },
  {
    name: "evaluation-components-NewsSwiper",
    path: "/evaluation/components/NewsSwiper",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/NewsSwiper.vue")
  },
  {
    name: "evaluation-components-Pricing",
    path: "/evaluation/components/Pricing",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Pricing.vue")
  },
  {
    name: "evaluation-components-Purchase",
    path: "/evaluation/components/Purchase",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Purchase.vue")
  },
  {
    name: "evaluation-components-Services",
    path: "/evaluation/components/Services",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Services.vue")
  },
  {
    name: "evaluation-components-Sponsors",
    path: "/evaluation/components/Sponsors",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Sponsors.vue")
  },
  {
    name: "evaluation-components-Testimonial",
    path: "/evaluation/components/Testimonial",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Testimonial.vue")
  },
  {
    name: "evaluation-components-TestimonialCard",
    path: "/evaluation/components/TestimonialCard",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/TestimonialCard.vue")
  },
  {
    name: "evaluation-data",
    path: "/evaluation/data",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/data.ts")
  },
  {
    name: "evaluation",
    path: "/evaluation",
    meta: indexlJ2D6PeADRMeta || {},
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/index.vue")
  },
  {
    name: "evaluations-id",
    path: "/evaluations/:id()",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluations/[id]/index.vue")
  },
  {
    name: "evaluations-Components-feedback",
    path: "/evaluations/Components/feedback",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluations/Components/feedback.vue")
  },
  {
    name: "evaluations-Components-ReEvaluation",
    path: "/evaluations/Components/ReEvaluation",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluations/Components/ReEvaluation.vue")
  },
  {
    name: "evaluations",
    path: "/evaluations",
    meta: indexhhLA8wrlkdMeta || {},
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluations/index.vue")
  },
  {
    name: "fail",
    path: "/fail",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/fail/index.vue")
  },
  {
    name: "faq-components-Contacts1Hero",
    path: "/faq/components/Contacts1Hero",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/faq/components/Contacts1Hero.vue")
  },
  {
    name: "faq-components-Details",
    path: "/faq/components/Details",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/faq/components/Details.vue")
  },
  {
    name: "faq-components-Faqs",
    path: "/faq/components/Faqs",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/faq/components/Faqs.vue")
  },
  {
    name: "faq-data",
    path: "/faq/data",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/faq/data.ts")
  },
  {
    name: "faq",
    path: "/faq",
    meta: indexMt4hGxByKEMeta || {},
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/faq/index.vue")
  },
  {
    name: "help-me-by-a-car-components-HeroSection",
    path: "/help-me-by-a-car/components/HeroSection",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/help-me-by-a-car/components/HeroSection.vue")
  },
  {
    name: "help-me-by-a-car-components-SearchCar",
    path: "/help-me-by-a-car/components/SearchCar",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/help-me-by-a-car/components/SearchCar.vue")
  },
  {
    name: "help-me-by-a-car-components-Specifications",
    path: "/help-me-by-a-car/components/Specifications",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/help-me-by-a-car/components/Specifications.vue")
  },
  {
    name: "help-me-by-a-car",
    path: "/help-me-by-a-car",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/help-me-by-a-car/index.vue")
  },
  {
    name: "impartiality-policy-components-Content",
    path: "/impartiality-policy/components/Content",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/impartiality-policy/components/Content.vue")
  },
  {
    name: "impartiality-policy",
    path: "/impartiality-policy",
    meta: indexmqvX87d7e1Meta || {},
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/impartiality-policy/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/index.vue")
  },
  {
    name: "inspection-components-CarSearchBox",
    path: "/inspection/components/CarSearchBox",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/CarSearchBox.vue")
  },
  {
    name: "inspection-components-ComparisonSlider",
    path: "/inspection/components/ComparisonSlider",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/ComparisonSlider.vue")
  },
  {
    name: "inspection-components-Cta",
    path: "/inspection/components/Cta",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Cta.vue")
  },
  {
    name: "inspection-components-Features",
    path: "/inspection/components/Features",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Features.vue")
  },
  {
    name: "inspection-components-Features2",
    path: "/inspection/components/Features2",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Features2.vue")
  },
  {
    name: "inspection-components-InspectionTypes",
    path: "/inspection/components/InspectionTypes",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/InspectionTypes.vue")
  },
  {
    name: "inspection-components-Integrations",
    path: "/inspection/components/Integrations",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Integrations.vue")
  },
  {
    name: "inspection-components-Saas1Footer",
    path: "/inspection/components/Saas1Footer",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Saas1Footer.vue")
  },
  {
    name: "inspection-components-Saas1Hero",
    path: "/inspection/components/Saas1Hero",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Saas1Hero.vue")
  },
  {
    name: "inspection-components-Testimonial",
    path: "/inspection/components/Testimonial",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Testimonial.vue")
  },
  {
    name: "inspection-components-TestimonialCard",
    path: "/inspection/components/TestimonialCard",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/TestimonialCard.vue")
  },
  {
    name: "inspection-components-UseCase",
    path: "/inspection/components/UseCase",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/UseCase.vue")
  },
  {
    name: "inspection-components-Work",
    path: "/inspection/components/Work",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Work.vue")
  },
  {
    name: "inspection-data",
    path: "/inspection/data",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/data.ts")
  },
  {
    name: "inspection",
    path: "/inspection",
    meta: indexIy1AtPIKcIMeta || {},
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/index.vue")
  },
  {
    name: "inspections-id",
    path: "/inspections/:id()",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspections/[id]/index.vue")
  },
  {
    name: "inspections",
    path: "/inspections",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspections/index.vue")
  },
  {
    name: "landing-mobile-app-1-assets-data-image",
    path: "/landing/mobile-app-1/assets/data/image",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/assets/data/image.ts")
  },
  {
    name: "landing-mobile-app-1-components-Brands",
    path: "/landing/mobile-app-1/components/Brands",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/Brands.vue")
  },
  {
    name: "landing-mobile-app-1-components-Cta",
    path: "/landing/mobile-app-1/components/Cta",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/Cta.vue")
  },
  {
    name: "landing-mobile-app-1-components-Faqs",
    path: "/landing/mobile-app-1/components/Faqs",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/Faqs.vue")
  },
  {
    name: "landing-mobile-app-1-components-Features1",
    path: "/landing/mobile-app-1/components/Features1",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/Features1.vue")
  },
  {
    name: "landing-mobile-app-1-components-Features2",
    path: "/landing/mobile-app-1/components/Features2",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/Features2.vue")
  },
  {
    name: "landing-mobile-app-1-components-MobileApp1Footer",
    path: "/landing/mobile-app-1/components/MobileApp1Footer",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/MobileApp1Footer.vue")
  },
  {
    name: "landing-mobile-app-1-components-MobileApp1Hero",
    path: "/landing/mobile-app-1/components/MobileApp1Hero",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/MobileApp1Hero.vue")
  },
  {
    name: "landing-mobile-app-1-components-ProcessSwiper",
    path: "/landing/mobile-app-1/components/ProcessSwiper",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/ProcessSwiper.vue")
  },
  {
    name: "landing-mobile-app-1-components-Testimonial",
    path: "/landing/mobile-app-1/components/Testimonial",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/Testimonial.vue")
  },
  {
    name: "landing-mobile-app-1-components-TestimonialCard",
    path: "/landing/mobile-app-1/components/TestimonialCard",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/TestimonialCard.vue")
  },
  {
    name: "landing-mobile-app-1-data",
    path: "/landing/mobile-app-1/data",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/data.ts")
  },
  {
    name: "landing-mobile-app-1",
    path: "/landing/mobile-app-1",
    meta: indexlzj2oa5G7iMeta || {},
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/index.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-AnchorNavigation",
    path: "/landing/mobile-app-1/ThemeComponents/AnchorNavigation",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/AnchorNavigation.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-Breadcrumb",
    path: "/landing/mobile-app-1/ThemeComponents/Breadcrumb",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/Breadcrumb.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-CustomSyntax",
    path: "/landing/mobile-app-1/ThemeComponents/CustomSyntax",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/CustomSyntax.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-Jarallax",
    path: "/landing/mobile-app-1/ThemeComponents/Jarallax",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/Jarallax.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-LightGallery",
    path: "/landing/mobile-app-1/ThemeComponents/LightGallery",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/LightGallery.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-LoadingSpinner",
    path: "/landing/mobile-app-1/ThemeComponents/LoadingSpinner",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/LoadingSpinner.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-Parallax",
    path: "/landing/mobile-app-1/ThemeComponents/Parallax",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/Parallax.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-PasswordInput",
    path: "/landing/mobile-app-1/ThemeComponents/PasswordInput",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/PasswordInput.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-Rellax",
    path: "/landing/mobile-app-1/ThemeComponents/Rellax",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/Rellax.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-VanillaTilt",
    path: "/landing/mobile-app-1/ThemeComponents/VanillaTilt",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/VanillaTilt.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-VideoPopup",
    path: "/landing/mobile-app-1/ThemeComponents/VideoPopup",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/VideoPopup.vue")
  },
  {
    name: "medical-components-Contact",
    path: "/medical/components/Contact",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/Contact.vue")
  },
  {
    name: "medical-components-Cta",
    path: "/medical/components/Cta",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/Cta.vue")
  },
  {
    name: "medical-components-Feature",
    path: "/medical/components/Feature",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/Feature.vue")
  },
  {
    name: "medical-components-FeatureCard",
    path: "/medical/components/FeatureCard",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/FeatureCard.vue")
  },
  {
    name: "medical-components-MedicalFooter",
    path: "/medical/components/MedicalFooter",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/MedicalFooter.vue")
  },
  {
    name: "medical-components-MedicalHero",
    path: "/medical/components/MedicalHero",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/MedicalHero.vue")
  },
  {
    name: "medical-components-News",
    path: "/medical/components/News",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/News.vue")
  },
  {
    name: "medical-components-NewsCard",
    path: "/medical/components/NewsCard",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/NewsCard.vue")
  },
  {
    name: "medical-components-ServiceCard",
    path: "/medical/components/ServiceCard",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/ServiceCard.vue")
  },
  {
    name: "medical-components-Services",
    path: "/medical/components/Services",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/Services.vue")
  },
  {
    name: "medical-components-Team",
    path: "/medical/components/Team",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/Team.vue")
  },
  {
    name: "medical-components-TeamCard",
    path: "/medical/components/TeamCard",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/TeamCard.vue")
  },
  {
    name: "medical-components-Testimonial",
    path: "/medical/components/Testimonial",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/Testimonial.vue")
  },
  {
    name: "medical-components-TestimonialCard",
    path: "/medical/components/TestimonialCard",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/TestimonialCard.vue")
  },
  {
    name: "medical-components-VideoReel",
    path: "/medical/components/VideoReel",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/VideoReel.vue")
  },
  {
    name: "medical-data",
    path: "/medical/data",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/data.ts")
  },
  {
    name: "medical",
    path: "/medical",
    meta: indexvIP8hZO7R3Meta || {},
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/index.vue")
  },
  {
    name: "news-id-components-ArticleCard",
    path: "/news/:id()/components/ArticleCard",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/ArticleCard.vue")
  },
  {
    name: "news-id-components-Articles",
    path: "/news/:id()/components/Articles",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/Articles.vue")
  },
  {
    name: "news-id-components-Comment",
    path: "/news/:id()/components/Comment",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/Comment.vue")
  },
  {
    name: "news-id-components-CommentForm",
    path: "/news/:id()/components/CommentForm",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/CommentForm.vue")
  },
  {
    name: "news-id-components-Jarallax",
    path: "/news/:id()/components/Jarallax",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/Jarallax.vue")
  },
  {
    name: "news-id-components-LightGallery",
    path: "/news/:id()/components/LightGallery",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/LightGallery.vue")
  },
  {
    name: "news-id-components-SinglePostHero",
    path: "/news/:id()/components/SinglePostHero",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/SinglePostHero.vue")
  },
  {
    name: "news-id-data",
    path: "/news/:id()/data",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/data.ts")
  },
  {
    name: "news-id",
    path: "/news/:id()",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/index.vue")
  },
  {
    name: "news-components-BlogCard",
    path: "/news/components/BlogCard",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/components/BlogCard.vue")
  },
  {
    name: "news-components-Content",
    path: "/news/components/Content",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/components/Content.vue")
  },
  {
    name: "news-components-Pagination",
    path: "/news/components/Pagination",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/components/Pagination.vue")
  },
  {
    name: "news-data",
    path: "/news/data",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/data.ts")
  },
  {
    name: "news",
    path: "/news",
    meta: index58NQmxYdQ7Meta || {},
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/index.vue")
  },
  {
    name: "our-clients",
    path: "/our-clients",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/our-clients/index.vue")
  },
  {
    name: "partners",
    path: "/partners",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/partners/index.vue")
  },
  {
    name: "payment-redirect",
    path: "/payment/redirect",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/payment/redirect/index.vue")
  },
  {
    name: "privacy-policy-components-Content",
    path: "/privacy-policy/components/Content",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/privacy-policy/components/Content.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: indexp4bAzB3xz3Meta || {},
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/privacy-policy/index.vue")
  },
  {
    name: "sales-agent-form",
    path: "/sales-agent-form",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/sales-agent-form/index.vue")
  },
  {
    name: "success",
    path: "/success",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/success/index.vue")
  },
  {
    name: "terms-and-condition-components-Content",
    path: "/terms-and-condition/components/Content",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/terms-and-condition/components/Content.vue")
  },
  {
    name: "terms-and-condition",
    path: "/terms-and-condition",
    meta: indexnjKZ45cuOWMeta || {},
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/terms-and-condition/index.vue")
  },
  {
    name: "trailer-inspection-and-half-trailers",
    path: "/trailer-inspection-and-half-trailers",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/trailer-inspection-and-half-trailers/index.vue")
  },
  {
    name: "trim",
    path: "/trim",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/trim/index.vue")
  },
  {
    name: "vin-decoder",
    path: "/vin-decoder",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/vin-decoder/index.vue")
  },
  {
    name: "warranties",
    path: "/warranties",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranties/index.vue")
  },
  {
    name: "warranty-form-autohub-components-Pricing",
    path: "/warranty-form-autohub/components/Pricing",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty-form-autohub/components/Pricing.vue")
  },
  {
    name: "warranty-form-autohub-components-PricingForMobile",
    path: "/warranty-form-autohub/components/PricingForMobile",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty-form-autohub/components/PricingForMobile.vue")
  },
  {
    name: "warranty-form-autohub-components-Saas3Hero",
    path: "/warranty-form-autohub/components/Saas3Hero",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty-form-autohub/components/Saas3Hero.vue")
  },
  {
    name: "warranty-form-autohub-components-WarrantyForm",
    path: "/warranty-form-autohub/components/WarrantyForm",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty-form-autohub/components/WarrantyForm.vue")
  },
  {
    name: "warranty-form-autohub",
    path: "/warranty-form-autohub",
    meta: indexlf463doIGXMeta || {},
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty-form-autohub/index.vue")
  },
  {
    name: "warranty-form",
    path: "/warranty-form",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty-form/index.vue")
  },
  {
    name: "warranty-components-CarSearchBox",
    path: "/warranty/components/CarSearchBox",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/CarSearchBox.vue")
  },
  {
    name: "warranty-components-Pricing",
    path: "/warranty/components/Pricing",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/Pricing.vue")
  },
  {
    name: "warranty-components-PricingForMobile",
    path: "/warranty/components/PricingForMobile",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/PricingForMobile.vue")
  },
  {
    name: "warranty-components-Saas3Hero",
    path: "/warranty/components/Saas3Hero",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/Saas3Hero.vue")
  },
  {
    name: "warranty-components-Testimonial",
    path: "/warranty/components/Testimonial",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/Testimonial.vue")
  },
  {
    name: "warranty-components-TestimonialCard",
    path: "/warranty/components/TestimonialCard",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/TestimonialCard.vue")
  },
  {
    name: "warranty-components-WarrantyFeature",
    path: "/warranty/components/WarrantyFeature",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/WarrantyFeature.vue")
  },
  {
    name: "warranty-components-Work",
    path: "/warranty/components/Work",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/Work.vue")
  },
  {
    name: "warranty-data",
    path: "/warranty/data",
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/data.ts")
  },
  {
    name: "warranty",
    path: "/warranty",
    meta: indexwXkA4sWPgsMeta || {},
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/index.vue")
  }
]