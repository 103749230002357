<script lang="ts" setup>
import { useUserStore, useUserProfileStore } from "@/store/userStore";
import { Icon } from "@iconify/vue";
import CheckIcon from "@iconify/icons-bx/bx-check";
import XIcon from "@iconify/icons-bx/bx-x";
import LanguageSwitcher from "./LanguageSwitcher.vue";
import { useI18n } from "vue-i18n";
import { onMounted, ref } from "vue";

const { t, locale } = useI18n();
const localePath = useLocalePath();
const storeUserProfile = useUserProfileStore();
const { $api } = useNuxtApp();
const router = useRouter();
const store = useUserStore();
const { isLoggedIn, user } = storeToRefs(store);
const { userProfile } = storeToRefs(storeUserProfile);

const isLoginModalVisible = ref(false);
const selectedPlan = ref(null);
const isOtpModalVisible = ref(false);
const isProfileModalVisible = ref(false);
const showModal = ref(false);
const phone = ref("");
const subscriptionplans = ref([]);
const activeSubscription = ref(null);
const dataSubscription = ref(null);
const subscription_plan_id = ref(null);
const isLoading = ref(false);
const error = ref(null);

// Navigation items
const navItems = [
  { to: "/", title: "home" },
  { to: "evaluation", title: "used_vehicle_prices" },
  { to: "inspection", title: "vehicle_inspection" },
  { to: "warranty", title: "vehicle_warranty" },
  { to: "#", title: "market_research" },
  { to: "branches", title: "branches" },
  { to: "contact", title: "contact_us" },
];

// Navigation items
//  const navItems = [
//   { to: "/", title: "الصفحة الرئيسية" },
//   { to: "evaluation", title: "اسعار المركبات المستعملة" },
//   { to: "inspection", title: "فحص المركبات" },
//   { to: "warranty", title: "ضمان المركبات" },
//  { to: "#", title: "أبحاث السوق" },
//  { to: "branches", title: "مواقعنا" },
//   { to: "contact", title: "تواصل معنا" },
// ];

// Computed property to filter subscription plans
const filteredSubscriptionPlans = computed(() => {
  if (!dataSubscription.value) {
    return subscriptionplans.value;
  }

  const subscriptionName = dataSubscription.value.name;

  if (subscriptionName === "Basic") {
    return subscriptionplans.value.filter(
      (plan) => plan.name === "Monthly" || plan.name === "Yearly"
    );
  } else if (subscriptionName === "Monthly") {
    return subscriptionplans.value.filter((plan) => plan.name === "Yearly");
  } else if (subscriptionName === "Yearly") {
    return subscriptionplans.value.filter((plan) => plan.name === "Yearly");
  }
  return subscriptionplans.value;
});

// Function to handle logout
async function handleLogout() {
  try {
    store.removeUserFromLocalStorage();
    storeUserProfile.removeUserProfileFromLocalStorage();

    // Clear localStorage data
    localStorage.removeItem("subscriptionPlans");
    localStorage.removeItem("dataSubscription");
    localStorage.removeItem("selectedPlan");
    localStorage.removeItem("activeSubscription");

    router.push(localePath("/"));
  } catch (error) {
    console.log(error);
  }
}

// Load cached subscription data from local storage
function loadCachedData() {
  const cachedSubscriptionPlans = localStorage.getItem("subscriptionPlans");
  if (cachedSubscriptionPlans) {
    subscriptionplans.value = JSON.parse(cachedSubscriptionPlans);
  }

  const cachedActiveSubscription = localStorage.getItem("dataSubscription");
  if (cachedActiveSubscription) {
    dataSubscription.value = JSON.parse(cachedActiveSubscription);
  }
}

// Save data to local storage
function saveToLocalStorage() {
  localStorage.setItem(
    "subscriptionPlans",
    JSON.stringify(subscriptionplans.value)
  );
  localStorage.setItem(
    "dataSubscription",
    JSON.stringify(dataSubscription.value)
  );
}

// Fetch available subscription plans
async function getSubscriptionPlans() {
  try {
    isLoading.value = true;
    const res = await $api("/subscription/plans");
    subscriptionplans.value = res.data.subscription_plans;
    saveToLocalStorage();
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
}

// Check if the user has an active subscription
async function checkActiveSubscription() {
  try {
    isLoading.value = true;
    const res = await $api("/subscription/check", {
      method: "POST",
      body: { service_type: "evaluation" },
    });
    activeSubscription.value = res?.data.user_subscription;
    dataSubscription.value = res?.data.subscription_plan;
    saveToLocalStorage();
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
}

// Call the subscription checking and fetching functions when logged in
watchEffect(() => {
  if (isLoggedIn.value) {
    loadCachedData();
    if (!subscriptionplans.value.length || !dataSubscription.value) {
      getSubscriptionPlans();
      checkActiveSubscription();
    }
  }
});

// Watch for changes in selectedPlan and update subscription_plan_id accordingly
watch(selectedPlan, (newPlan) => {
  if (newPlan && (newPlan.name === "Monthly" || newPlan.name === "Yearly")) {
    subscription_plan_id.value = newPlan.id;
  } else {
    subscription_plan_id.value = null;
  }
});

// Handle card click (plan selection)
function handleCardClick(subscriptionPlan: any) {
  selectedPlan.value = subscriptionPlan;
  localStorage.setItem("selectedPlan", JSON.stringify(subscriptionPlan));
}

// Select the first plan that's not the active subscription
watch(filteredSubscriptionPlans, (newPlans) => {
  if (newPlans.length) {
    const firstNonActivePlan = newPlans.find(
      (plan) => plan.id !== dataSubscription.value?.id
    );
    if (firstNonActivePlan) {
      selectedPlan.value = firstNonActivePlan;
    }
  }
});

// Computed property for modal title
const modalTitle = computed(() => {
  return dataSubscription.value?.name === "Yearly"
    ? t("current_package")
    : t("choose_pricing_package");
});

onMounted(() => {
  locale.value = "ar";
  isLoading.value = false;
});
</script>

<template>
  <BNavbar toggleable="lg" class="bg-white app-navbar">
    <BNavbarBrand :to="$localePath('/')">
      <img src="/assets/images/logo.png" loading="lazy" alt="logo" />
    </BNavbarBrand>
    <BNavbarToggle target="nav-collapse" class="border-0" />
    <BCollapse id="nav-collapse" is-nav class="custom-nav">
      <BNavbarNav class="me-auto gap-2 align-items-center">
        <BNavItem class="d-block d-md-none navitem-top-sm-only">
          <img src="/assets/images/logo.png" loading="lazy" alt="logo" />
          <BNavbarToggle target="nav-collapse" class="border-0">
            <template #default="{ expanded }">
              <img
                src="/assets/images/cross.svg"
                width="20px"
                height="auto"
                loading="lazy"
                alt="cross"
              />
            </template>
          </BNavbarToggle>
        </BNavItem>
        <BNavItem
          v-for="navItem in navItems"
          :key="navItem.title"
          :to="$localePath(navItem.to)"
          :class="{ 'active-link': $route.path === $localePath(navItem.to) }"
          class="text-capitalize"
        >
          {{ $t(navItem.title) }}
        </BNavItem>
        <div class="text-primary"><LanguageSwitcher /></div>
      </BNavbarNav>
      <BNavbarNav class="mb-2 mb-lg-0 d-lg-none d-block">
      <div
        v-if="isLoggedIn"
        class="d-flex justify-content-center align-items-center mx-2"
      >
        <div v-if="dataSubscription?.name_arabic">
          <button
            v-if="isLoading === false"
            @click="showModal = true"
            class="bg-primary text-white px-4 py-1 rounded"
          >
            {{
              locale === "ar"
                ? dataSubscription?.name_arabic
                : dataSubscription?.name
            }}
          </button>
        </div>
        <div class="">
          <img
            class="dropdown-toggle border-0 ms-2 pe-2 bg-transparent caret"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            src="/assets/images/caret-down-fill.svg"
            width="30px"
            height="auto"
            loading="lazy"
            alt="caret down fill"
          />
          <ul
            :style="{
              right: locale !== 'ar' ? '0' : 'auto',
              left: locale === 'ar' ? '0' : 'auto',
            }"
            class="dropdown-menu"
          >
            <li>
              <a class="dropdown-item text-end" href="/dashboard">{{
                $t("control_panel")
              }}</a>
            </li>
            <li>
              <a class="dropdown-item text-end" href="/dashboard/profile">{{
                $t("profile")
              }}</a>
            </li>
            <li>
              <p
                class="px-3 py-1 pb-2 mt-2 bg-primary text-white rounded text-end"
                @click.prevent="handleLogout"
              >
                {{ $t("logout") }}
              </p>
            </li>
          </ul>
        </div>
        <div class="">
          <BAvatar
            v-if="userProfile?.user_image"
            :src="userProfile?.user_image"
            rounded="circle"
            variant="secondary"
            class="p-0"
            size="62px"
          />
          <BAvatar
            v-else
            rounded="circle"
            variant="secondary"
            size="44px"
            class="p-0"
          >
            <img
              src="/assets/images/avatar-default.jpg"
              class="img-fluid"
              loading="lazy"
              alt="avatar"
            />
          </BAvatar>
        </div>
      </div>
      <BNavItem v-else>
        <BButton
          variant="primary"
          @click.stop="isLoginModalVisible = true"
          class="fs-16 fw-600 px-3 h-auto py-3"
          size="lg"
        >
          {{ $t("login_register") }}
        </BButton>
      </BNavItem>
    </BNavbarNav>
    </BCollapse>

    <BNavbarNav class="mb-2 mb-lg-0 d-lg-block d-none">
      <div
        v-if="isLoggedIn"
        class="d-flex justify-content-center align-items-center mx-2"
      >
        <div v-if="dataSubscription?.name_arabic">
          <button
            v-if="isLoading === false"
            @click="showModal = true"
            class="bg-primary text-white px-4 py-1 rounded"
          >
            {{
              locale === "ar"
                ? dataSubscription?.name_arabic
                : dataSubscription?.name
            }}
          </button>
        </div>
        <div class="">
          <img
            class="dropdown-toggle border-0 ms-2 pe-2 bg-transparent caret"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            src="/assets/images/caret-down-fill.svg"
            width="30px"
            height="auto"
            loading="lazy"
            alt="caret down fill"
          />
          <ul
            :style="{
              right: locale !== 'ar' ? '0' : 'auto',
              left: locale === 'ar' ? '0' : 'auto',
            }"
            class="dropdown-menu"
          >
            <li>
              <a class="dropdown-item text-end" href="/dashboard">{{
                $t("control_panel")
              }}</a>
            </li>
            <li>
              <a class="dropdown-item text-end" href="/dashboard/profile">{{
                $t("profile")
              }}</a>
            </li>
            <li>
              <p
                class="px-3 py-1 pb-2 mt-2 bg-primary text-white rounded text-end"
                @click.prevent="handleLogout"
              >
                {{ $t("logout") }}
              </p>
            </li>
          </ul>
        </div>
        <div class="">
          <BAvatar
            v-if="userProfile?.user_image"
            :src="userProfile?.user_image"
            rounded="circle"
            variant="secondary"
            class="p-0"
            size="62px"
          />
          <BAvatar
            v-else
            rounded="circle"
            variant="secondary"
            size="44px"
            class="p-0"
          >
            <img
              src="/assets/images/avatar-default.jpg"
              class="img-fluid"
              loading="lazy"
              alt="avatar"
            />
          </BAvatar>
        </div>
      </div>
      <BNavItem v-else>
        <BButton
          variant="primary"
          @click.stop="isLoginModalVisible = true"
          class="fs-16 fw-600 px-3 h-auto py-3"
          size="lg"
        >
          {{ $t("login_register") }}
        </BButton>
      </BNavItem>
    </BNavbarNav>
  </BNavbar>
  <AppLoginModal
    v-model:visible="isLoginModalVisible"
    v-model:phone="phone"
    @success="isOtpModalVisible = true"
  />
  <AppOtpModal
    v-model:visible="isOtpModalVisible"
    :phone="phone"
    @success="phone = ''"
    @openProfileModal="
      () => {
        phone = '';
        isProfileModalVisible = true;
      }
    "
  />
  <AppProfileModal v-model:visible="isProfileModalVisible" />

  <AppModal
    :visible="showModal"
    @update:visible="(v: any) => (showModal = v)"
    ref="carouselRef"
    class="d-lg-flex justify-content-center align-items-center"
  >
    <template #default="{ close }">
      <div class="modal-body-content w-auto">
        <ModelCardContainer :title="modalTitle">
          <div
            v-if="filteredSubscriptionPlans.length"
            class="w-full d-lg-flex gap-4"
          >
            <div
              v-for="plan in filteredSubscriptionPlans"
              :key="plan.id"
              class="w-full border mt-lg-0 mt-2 rounded-3 h-100 shadow-sm mb-3 mb-lg-0 mb-md-0 cursor-pointer"
            >
              <div
                @click="handleCardClick(plan)"
                :value="plan"
                :class="[
                  'card h-100 border-0 border-end cursor-pointer',
                  {
                    'bg-primary text-white border-primary':
                      selectedPlan?.id === plan.id ||
                      plan?.id === dataSubscription?.id,
                  },
                ]"
              >
                <div class="card-body text-end p-5">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <p class="mb-2 fs-14 fw-800">
                      {{ locale === "ar" ? plan?.name_arabic : plan?.name }}
                    </p>
                    <div>
                      <p
                        v-if="dataSubscription?.name !== plan?.name"
                        class="mb-2 fs-14 fw-800"
                      >
                        {{ plan?.price }} {{ $t("currency_short") }}
                        <span>
                          <small v-if="plan?.name === 'Monthly'" class="fw-500"
                            >/{{ $t("monthly") }}</small
                          >
                          <small v-else class="fw-500"
                            >/{{ $t("yearly") }}</small
                          >
                        </span>
                      </p>
                      <p
                        v-else
                        class="mb-2 fs-14 fw-800 bg-primary py-2 px-3 rounded text-white"
                      >
                        {{ $t("free") }}
                      </p>
                    </div>
                  </div>
                  <p>
                    {{
                      locale === "ar"
                        ? plan?.description_arabic
                        : plan?.description
                    }}
                  </p>

                  <div
                    v-for="detail_item in plan?.detail_items"
                    :key="detail_item.id"
                  >
                    <li class="d-flex mb-2">
                      <Icon
                        v-if="detail_item?.active === true"
                        :icon="CheckIcon"
                        class="bx fs-14 me-1 mt-1 bg-primary text-white rounded-circle"
                      ></Icon>
                      <Icon
                        v-else
                        :icon="XIcon"
                        class="bx fs-xl me-1 mt-1 bg-primary text-white rounded-circle"
                      ></Icon>
                      <span class="me-1">{{
                        locale === "ar"
                          ? detail_item?.title_arabic
                          : detail_item?.title
                      }}</span>
                      <svg
                        v-b-popover.hover.top="
                          locale === 'ar'
                            ? detail_item?.description_arabic
                            : detail_item?.description
                        "
                        title="Popover Title"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#9c9c9c"
                        class="bi bi-info-circle mt-1 me-1"
                        viewBox="0 0 16 16"
                        v-if="detail_item?.description !== null"
                      >
                        <path
                          d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"
                        />
                        <path
                          d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
                        />
                      </svg>
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <a v-if="dataSubscription?.name !== 'Yearly'" href="/checkout">
            <div
              class="w-full mx-auto mt-5 text-center bg-primary text-white py-2 rounded"
            >
              {{ $t("upgrade_now") }}
            </div>
          </a>
        </ModelCardContainer>
      </div>
    </template>
  </AppModal>
</template>

<style lang="css" scoped>
.app-navbar {
  background-color: #ffffff;
  border-bottom: 1px solid rgba(195, 212, 233, 0.4);
  height: 100%;
  width: 100%;
  max-height: 92px;
  z-index: 1000;
  position: fixed;
  top: 0;
}

.app-navbar :deep(.nav-link) {
  font-family: inherit;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  text-align: right;
  text-transform: capitalize;
  color: #2d3291;
}

.app-navbar :deep(.navitem-top-sm-only) a {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-toggle::after {
  margin-right: -0.5rem;
  content: "" !important;
}

.app-navbar :deep(.navbar-collapse) {
  flex-grow: 0;
  gap: 20px;
}

.dropdown-menu.show {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  padding: 10px !important;
}

.dropdown-item:hover {
  background-color: #2d3291;
  color: white !important;
  border-radius: 5px;
}

.active-link {
  color: #2d3291;
  background-color: #ffffff;
  border-bottom: 2px solid #2d3291;
  padding: 10px 15px;
  border-radius: 0;
}

.subscription-menu {
  width: 400px;
}

.h-100 {
  height: 350px !important;
}

.modal-body-content {
  max-width: 100%;
  width: auto; /* Adjust to content */
}

/* Disable hover effect */
.dropdown-menu {
  display: none !important; /* Hide the dropdown menu by default */
}

.dropdown:hover .dropdown-menu {
  display: none !important; /* Ensure it does not show on hover */
}

.dropdown-menu.show {
  display: block !important; /* Show the dropdown menu when 'show' class is added */
}

@media (min-width: 768px) and (max-width: 1325px) {
  .app-navbar :deep(.nav-link) {
    font-size: 8px;
  }
}
</style>
