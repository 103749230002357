<template>
  <div class="container">
    <div class="align-items-center">
      <div class="d-flex align-items-center justify-content-center my-3">
        <img
          src="../../../assets/images/logo.png"
          width="100px"
          height="20px"
          loading="lazy"
          alt="logo"
        />
      </div>
      <h3 class="fw-semibold text-primary text-center">
        طلب اشتراك في ضمان
        <img
          src="../../../assets/images/autohub_logo.png"
          width="100px"
          height="20px"
          loading="lazy"
          alt="autohub logo"
        />
      </h3>
    <p class="fw-semibold text-primary text-center">بالتعاون مع كارترست</p>
     
    </div>
    <div v-if="!isFormValid" class="text-danger text-center my-3">
      يرجى ملء جميع الحقول المطلوبة.
    </div>
    <form @submit.prevent="submitForm">
      <!-- Make -->
      <div class="mb-3">
        <label for="" class="fs-16 fw-700"> {{ $t("make") }} </label>
        <VueSelect
          v-model="form.manufacturer_id"
          :options="paginated"
          label="name"
          :reduce="(record:any) => record.id"
          class="car-search-select"
          :placeholder="$t('make')"
          :loading="isLoadingManufacturers"
          @open="onOpen"
          @close="onClose"
          :filterBy="filterByManufacturer"
          :dir="isRTL ? 'ltr' : 'rtl'"
        >
          <template #selected-option="{ name_arabic, name, make_logo }">
            <div
              :dir="isRTL ? 'rtl' : 'ltr'"
              class="d-flex gap-3 align-items-center justify-content-start w-100"
            >
              <img
                :src="make_logo"
                style="width: 25px; height: auto"
                loading="lazy"
                alt="make logo"
              />
              <span class="fs-16 flex-1">{{
                locale === "ar" ? name_arabic : name
              }}</span>
            </div>
          </template>
          <template #option="{ name_arabic, name, make_logo }">
            <div
              :dir="isRTL ? 'rtl' : 'ltr'"
              class="d-flex gap-3 align-items-center justify-content-start w-100"
            >
              <img
                :src="make_logo"
                style="width: 20px; height: auto"
                loading="lazy"
                alt="make logo"
              />
              <span class="fs-16">{{
                locale === "ar" ? name_arabic : name
              }}</span>
            </div>
          </template>
          <template #no-options>
            <span slot="no-options">{{ $t("no_options_message") }} </span>
          </template>
        </VueSelect>
      </div>

      <div class="mb-3">
        <label for="" class="fs-16 fw-700"> {{ $t("model") }} </label>
        <VueSelect
          v-model="form.model_id"
          :options="models"
          label="name"
          :reduce="(record:any) => record.id"
          clear-search-on-select
          class="car-search-select car-search"
          :placeholder="$t('model')"
          :loading="isLoadingModels"
          :disabled="
            isLoadingManufacturers ||
            isLoadingYears ||
            isLoadingModels ||
            !form.manufacturer_id
          "
          :dir="isRTL ? 'ltr' : 'rtl'"
        >
          <!-- clear-search-on-blur -->
          <template #selected-option="{ name_arabic, name }">
            <div
              :dir="isRTL ? 'rtl' : 'ltr'"
              class="d-flex gap-3 align-items-center justify-content-start w-100"
            >
              <img
                src="~/assets/images/car.svg"
                style="width: 18px; height: auto"
                loading="lazy"
                alt="car"
              />
              <span class="fs-16 flex-1">{{
                locale === "ar" ? name_arabic : name
              }}</span>
            </div>
          </template>
          <template #option="{ name_arabic, name }">
            <div
              :dir="isRTL ? 'rtl' : 'ltr'"
              class="d-flex gap-3 align-items-center justify-content-start w-100"
            >
              <span class="fs-16">{{
                locale === "ar" ? name_arabic : name
              }}</span>
            </div>
          </template>
          <template #no-options>
            <span slot="no-options"> {{ $t("no_options_message") }} </span>
          </template>
        </VueSelect>
      </div>

      <!-- Year -->
      <div class="mb-3">
        <label for="" class="fs-16 fw-700">{{ $t("year") }}</label>
        <VueSelect
          v-model="form.year_id"
          :options="years"
          label="name"
          :reduce="(record:any) => record.id"
          class="car-search-select"
          :placeholder="$t('year')"
          :loading="isLoadingYears"
          :disabled="
            isLoadingYears ||
            isLoadingManufacturers ||
            isLoadingModels ||
            !form.manufacturer_id
          "
          :dir="isRTL ? 'ltr' : 'rtl'"
        >
          <template #selected-option="{ name }">
            <div
              class="d-flex gap-3 align-items-center justify-content-start w-100"
            >
              <span class="fs-16 flex-1">{{ name }}</span>
              <img
                src="~/assets/images/calendar.svg"
                style="width: 14px; height: auto"
                loading="lazy"
                alt="car"
              />
            </div>
          </template>
          <template #option="{ name }">
            <div
              :dir="isRTL ? 'rtl' : 'ltr'"
              class="d-flex gap-3 align-items-center justify-content-start w-100"
            >
              <span class="fs-16">{{ name }}</span>
            </div>
          </template>
          <template #no-options>
            <span slot="no-options">{{ $t("no_options_message") }} </span>
          </template>
        </VueSelect>
      </div>

      <!-- Phone Number -->
      <div class="mb-3">
        <div class="position-relative">
          <AppNumberField
            v-model="form.phone"
            :label="$t('mobile_number')"
            :must="true"
            type="number"
            :maxlength="10"
            pattern="[0-9]*"
            inputmode="numeric"
            placeholder="05XXXXXXXX"
            name="phone"
          />
          <span v-if="formErrors.phone" class="text-danger">
            {{ formErrors.phone }}
          </span>
        </div>
      </div>
      <p class="d-flex align-items-center justify-content-center">سيتم فحص المركبة و اصدار الضمان قبل استلام مركبتك في المركز</p>
      <!-- Submit Button -->
      <div class="col-12 text-center">
        <button
          type="submit"
          class="btn"
          :class="isFormValid ? 'btn-primary' : 'bg-gray'"
          :disabled="!isFormValid"
        >
          {{ $t("send") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import VueSelect from "vue-select";
import { ref, computed, nextTick, onMounted, watch } from "vue";
import { defineEmits, defineProps } from "vue";
const { locale } = useI18n();
// Setup
const { $api } = useNuxtApp();
const emit = defineEmits<{
  pause: [payload: void];
  "update:modal_value": [payload: boolean];
  "update:showModal_value": [payload: boolean];
  "update:reference_number": [payload: string];
}>();

// Inside the <script setup>
const props = defineProps({
  warranty_type: {
    type: Number,
    required: true,
  },
});
watch(
  () => props.warranty_type,
  (newVal) => {
    form.value.warranty_type = newVal;
  }
);
const { getWarranty, getWarrantyCoverage } = useWarrantyForm();
const {
  manufacturers: manufacturers1,
  getAllManufacturers,
  isLoading: isLoadingManufacturers,
} = useManufacturer();
const {
  models,
  getModelsByManufacturer,
  isLoading: isLoadingModels,
} = useModels();
const {
  years,
  getYearsByManufactureAndModel,
  isLoading: isLoadingYears,
} = useYears();

const formErrors = ref<{ [key: string]: string }>({});

const form = ref<{
  phone: string;
  manufacturer_id: number | null;
  model_id: number | null;
  year_id: number | null;
  warranty_type: number | null;
  reference_number: string;
}>({
  phone: "",
  manufacturer_id: null,
  model_id: null,
  year_id: null,
  warranty_type: props.warranty_type,
  reference_number: "",
});
const load = ref();
const x = ref<{
  observer: IntersectionObserver | null;
  limit: number;
  search: string;
}>({
  observer: null,
  limit: 100,
  search: "",
});

const paginated = computed(() => manufacturers1.value.slice(0, x.value.limit));
const hasNextPage = computed(
  () => paginated.value.length < manufacturers1.value.length
);

async function onOpen() {
  if (hasNextPage.value) {
    await nextTick();
    x.value.observer?.observe(load.value);
  }
}

function onClose() {
  x.value.observer?.disconnect();
}

async function infiniteScroll([
  { isIntersecting, target },
]: IntersectionObserverEntry[]) {
  if (isIntersecting) {
    const ul = (target as HTMLElement).offsetParent as HTMLElement;
    const scrollTop = (target as HTMLElement).offsetParent?.scrollTop || 0;
    x.value.limit += 10;
    await nextTick();
    ul.scrollTop = scrollTop;
  }
}

const generateReferenceNumber = () => {
  return "REF-" + Math.random().toString(36).substring(2, 10).toUpperCase();
};

onMounted(() => {
  getAllManufacturers();
  getWarranty();
  getWarrantyCoverage();
  x.value.observer = new IntersectionObserver(infiniteScroll);
  form.value.reference_number = generateReferenceNumber();
  emit("update:reference_number", form.value.reference_number);
});

watch(
  () => form.value.manufacturer_id,
  (v) => {
    if (v) {
      form.value.model_id = null;
      form.value.year_id = null;
      getModelsByManufacturer(v);
    }
    emit("pause");
  }
);

watch(
  [() => form.value.manufacturer_id, () => form.value.model_id],
  ([manufacturer_id, model_id]) => {
    if (manufacturer_id && model_id) {
      getYearsByManufactureAndModel({
        service_name: "inspection",
        manufacturer_id,
        model_id,
      });
    }
  }
);
function filterByManufacturer(option: any, label: any, search: any) {
  return option.tags.toLowerCase().includes(search.toLowerCase());
}
function resetForm() {
  form.value = {
    phone: "",
    manufacturer_id: null,
    model_id: null,
    year_id: null,
    warranty_type: null,
    reference_number: "",
  };
}

const validateFormData = () => {
  formErrors.value = {};
  if (!form.value.phone) {
    formErrors.value.phone = "رقم الجوال مطلوب";
  } else if (form.value.phone.length !== 10) {
    formErrors.value.phone = "رقم الجوال غير صحيح";
  } else if (!/^[0-9]*$/.test(form.value.phone)) {
    formErrors.value.phone = "رقم الجوال غير صحيح  ";
  }
  if (!form.value.manufacturer_id)
    formErrors.value.manufacturer_id = "الشركة المصنعة مطلوبة.";
  if (!form.value.model_id) formErrors.value.model_id = "رقم الجوال مطلوب.";
  if (!form.value.year_id) formErrors.value.year_id = "اسم البنك مطلوب.";
};

const isFormValid = computed(() => {
  return (
    form.value.phone &&
    form.value.manufacturer_id &&
    form.value.model_id &&
    form.value.year_id
  );
});

const submitForm = async () => {
  formErrors.value = {};
  if (!isFormValid.value) {
    alert("يرجى ملء جميع الحقول المطلوبة.");
    return;
  }
  validateFormData();
  if (Object.keys(formErrors.value).length > 0) {
    return;
  }
  const formData = new FormData();
  formData.append("phone", form.value.phone);
  formData.append("make_id", String(form.value.manufacturer_id));
  formData.append("model_id", String(form.value.model_id));
  formData.append("year_id", String(form.value.year_id));
  formData.append("warranty_type", String(form.value.warranty_type));
  formData.append("reference_number", form.value.reference_number);
  try {
    const response: {
      success: boolean;
      message?: string;
      errors?: { [key: string]: string[] };
    } = await $api("/submit/warranty-application", {
      method: "POST",
      body: formData,
    });
    if (response.success) {
      resetForm();
      emit("update:showModal_value", false);
      emit("update:modal_value", true);
    } else {
      console.error("Error submitting form:", response.message);
      if (response && response.errors) {
        for (const key in response.errors) {
          formErrors.value[key] = response.errors[key][0];
        }
      } else {
        formErrors.value.apiError =
          "An error occurred while submitting the form.";
      }
    }
  } catch (error) {
    console.error("Error during form submission:", error);
    formErrors.value.apiError = "An error occurred while submitting the form.";
  }
};

// Ensure computed property reacts to locale changes
const isRTL = computed(() => {
  return locale.value === "ar";
});
</script>
<style>
@import "vue-select/dist/vue-select.css";
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.loader {
  text-align: center;
  color: #bbbbbb;
}

.reqired-start {
  color: red;
}

.success-text {
  color: rgb(1, 205, 1);
}
.car-search-select .vs__dropdown-toggle {
  /* height: 60px; */
  border: none;
  background: #fff;
  padding: 0;
  border-radius: 8px;
  flex-direction: row-reverse;
  padding: 0.5rem;
  align-items: center;
  min-height: 60px;
}

.car-search-select.selected .vs__search {
  display: none;
}

.car-search-select .vs__selected {
  margin: 0;
  justify-content: flex-end;
}
.car-search-select .vs__search::placeholder,
.car-search-select .vs__dropdown-menu {
  width: 100%;
  background: #fff;
  border-radius: 8px;
  border: none;
  outline: none;
  text-align: end;
  font-size: 16px;
  font-weight: bold;
  color: #1f2b3e;
}

.car-search-select .vs__actions {
  padding-inline: 0.5rem;
  padding-top: 0;
}

.car-search-select .vs__clear {
  display: none;
}

.car-search-select .vs__search::placeholder {
  color: rgba(31, 43, 62, 0.679715);
  font-weight: normal;
}
.car-search-select .vs__search::placeholder {
  background-color: transparent;
}

.car-search-select .vs__open-indicator {
  fill: #394066;
  width: 20px;
}

.car-search-select .vs__dropdown-toggle .vs__selected {
  flex-grow: 1;
}

.car-search-select .vs__dropdown-menu .vs__dropdown-option {
  padding: 1rem !important;
}

.car-search-select .vs__dropdown-menu::-webkit-scrollbar {
  width: 5px;
}

.car-search-select
  .vs__dropdown-menu
  .vs__dropdown-option.vs__dropdown-option--highlight {
  background-color: whitesmoke;
  color: #394066;
}

.car-search-select .vs__dropdown-toggle .vs__selected-options {
  /* hotfix */
  flex-direction: row-reverse;
}
</style>

<style scoped>
label {
  font-family: "UniversNext", sans-serif;
  font-weight: bold;
}

button.submit-button:disabled {
  background-color: slategray;
}
</style>
