import { useLanguageStore } from '@/store/language';


export default defineNuxtPlugin(async (nuxtApp) => {
  const languageStore = useLanguageStore();
  const lang = languageStore.selectedLanguage;

  // Access the i18n instance using nuxtApp.$i18n
  const i18n = nuxtApp.$i18n;

  // If translations are already cached, use them
  const translations = languageStore.cachedTranslations[lang] || await languageStore.changeLanguage(lang);

  // Set translations to the i18n instance
  if (translations) {
    // Update the translations
    i18n.setLocaleMessage(lang, translations);

    // Change the locale
    i18n.locale.value = lang;
  }
});
