import revive_payload_client_4sVQNw7RlN from "C:/Users/LENOVO/Downloads/Cartrust/website/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "C:/Users/LENOVO/Downloads/Cartrust/website/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "C:/Users/LENOVO/Downloads/Cartrust/website/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "C:/Users/LENOVO/Downloads/Cartrust/website/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "C:/Users/LENOVO/Downloads/Cartrust/website/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "C:/Users/LENOVO/Downloads/Cartrust/website/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "C:/Users/LENOVO/Downloads/Cartrust/website/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "C:/Users/LENOVO/Downloads/Cartrust/website/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "C:/Users/LENOVO/Downloads/Cartrust/website/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "C:/Users/LENOVO/Downloads/Cartrust/website/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "C:/Users/LENOVO/Downloads/Cartrust/website/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_3rw1Iqhpn2 from "C:/Users/LENOVO/Downloads/Cartrust/website/node_modules/vue3-perfect-scrollbar/nuxt/dist/runtime/plugin.mjs";
import useDirectives_W0tXrzvhuP from "C:/Users/LENOVO/Downloads/Cartrust/website/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/useDirectives.mjs";
import createBootstrap_ywvW1KIeJ9 from "C:/Users/LENOVO/Downloads/Cartrust/website/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/createBootstrap.mjs";
import i18n_yfWm7jX06p from "C:/Users/LENOVO/Downloads/Cartrust/website/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import i18n_VfGcjrvSkj from "C:/Users/LENOVO/Downloads/Cartrust/website/plugins/i18n.ts";
import api_GFfDXud5Cr from "C:/Users/LENOVO/Downloads/Cartrust/website/plugins/api.ts";
import globals_qEnHRCYwqu from "C:/Users/LENOVO/Downloads/Cartrust/website/plugins/globals.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_3rw1Iqhpn2,
  useDirectives_W0tXrzvhuP,
  createBootstrap_ywvW1KIeJ9,
  i18n_yfWm7jX06p,
  i18n_VfGcjrvSkj,
  api_GFfDXud5Cr,
  globals_qEnHRCYwqu
]