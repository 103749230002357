import axios from "axios";
const runtimeConfig = useRuntimeConfig();
const config = useRuntimeConfig();

export async function getPaymentOptions(
  orderValue: number,
  currency: string,
  countryCode: undefined,
  phoneNumber: undefined
) {
  try {
    const response = await fetch(
      `${config.public.tamaraApiURL}/checkout/payment-types?country=${countryCode}&phone=${phoneNumber}&currency=${currency}&order_value=${orderValue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${config.public.authToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Failed to fetch:", error);
  }
}

export async function checkPaymentOptions(
  orderValue: number,
  currency: string,
  countryCode: undefined,
  phoneNumber: undefined,
  isVIP: undefined
) {
  try {
    const response = await fetch(
      ` ${config.public.tamaraApiURL}/checkout/payment-options-pre-check`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${config.public.authToken}`,
        },
        body: JSON.stringify({
          country: countryCode,
          order_value: {
            amount: orderValue,
            currency: currency,
          },
          phone_number: phoneNumber,
          is_vip: isVIP,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Failed to fetch:", error);
  }
}

export async function getIDVerification(
  phoneNumber: string | undefined,
  countryCode: string | undefined,
) {
  try {
    const response = await fetch(
      `${config.public.tamaraApiURL}/merchants/customer/id-verification-status?phone_number=966${phoneNumber}&country_code=${countryCode}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${config.public.authToken}`,
        },
      }
    );

    if (!response.ok) { // Check if response is not ok
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Failed to fetch:", error);
  }
}


export async function createCheckoutSession(orderDetails: any) {
  try {
    const response = await axios.post(
     `${config.public.tamaraApiURL}/checkout`,
      orderDetails,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${config.public.authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating checkout session:", error);
    throw error;
  }
}

export async function authoriseOrder(orderId: any) {
  try {
    const response = await axios.post(
      `${config.public.tamaraApiURL}/orders/${orderId}/authorise`,
      {
        order_id: orderId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${config.public.authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error capturing order:", error);
    throw error;
  }
}

export async function captureOrder(orderDetails: any) {
  try {
    const response = await axios.post(
      `${config.public.tamaraApiURL}/payments/capture`,
      orderDetails,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${config.public.authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error capturing order:", error);
    throw error;
  }
}

export async function refundOrder(orderId: any) {
  try {
    const response = await axios.post(
      `${config.public.tamaraApiURL}/payments/simplified-refund/${orderId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error refunding order:", error);
    throw error;
  }
}
