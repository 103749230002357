import { useLanguageStore } from '@/store/language';

export default defineNuxtRouteMiddleware(async () => {
  const languageStore = useLanguageStore();
  const lang = languageStore.selectedLanguage;

  // Wait until language is loaded (ensure it is fully set before navigating)
  if (!languageStore.cachedTranslations[lang]) {
    await languageStore.changeLanguage(lang); 
  }
});
