
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as _91_46_46_46slug_93D3gDHTpvqaMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/[...slug].vue?macro=true";
import { default as About1Hero6YWmcTbgLiMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/about/components/About1Hero.vue?macro=true";
import { default as AwardsLXuWeCQzRSMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/about/components/Awards.vue?macro=true";
import { default as BenefitsqFDm5EuRxdMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/about/components/Benefits.vue?macro=true";
import { default as ContactOeDNm1zo0MMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/about/components/Contact.vue?macro=true";
import { default as SocialNetworkCiaOcKxMTkMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/about/components/SocialNetwork.vue?macro=true";
import { default as TeamNcUolDaeP4Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/about/components/Team.vue?macro=true";
import { default as TeamCardw1q1ENXqVbMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/about/components/TeamCard.vue?macro=true";
import { default as Testimonialp1K2ACljmQMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/about/components/Testimonial.vue?macro=true";
import { default as TestimonialCardOk0SLtx5TkMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/about/components/TestimonialCard.vue?macro=true";
import { default as VideoReel5rZhBhFE4dMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/about/components/VideoReel.vue?macro=true";
import { default as dataX5WgC7iSwjMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/about/data.ts?macro=true";
import { default as indexjj0nnwtJvrMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/about/index.vue?macro=true";
import { default as index0mhanhKRBfMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/branches/index.vue?macro=true";
import { default as indexbhrxYAqWmWMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/cancel/index.vue?macro=true";
import { default as indexkOqhoMgTTOMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/career/index.vue?macro=true";
import { default as FaqsHWrWpSTI5UMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/cartrust-agent/components/Faqs.vue?macro=true";
import { default as FeaturesaaRcqh6iS0Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/cartrust-agent/components/Features.vue?macro=true";
import { default as OpportunitiesZYkYgooQoyMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/cartrust-agent/components/Opportunities.vue?macro=true";
import { default as PlanComparison2md5qK4YnwMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/cartrust-agent/components/PlanComparison.vue?macro=true";
import { default as Pricingato522VnZMMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/cartrust-agent/components/Pricing.vue?macro=true";
import { default as Saas3HeromnLrEIcBo7Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/cartrust-agent/components/Saas3Hero.vue?macro=true";
import { default as TestimonialOF9yD7HHOTMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/cartrust-agent/components/Testimonial.vue?macro=true";
import { default as TestimonialCardtzmw9zU8DcMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/cartrust-agent/components/TestimonialCard.vue?macro=true";
import { default as WorkeVs9inXFc3Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/cartrust-agent/components/Work.vue?macro=true";
import { default as data1ANTIzOH2SMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/cartrust-agent/data.ts?macro=true";
import { default as indexaUJbwO61H2Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/cartrust-agent/index.vue?macro=true";
import { default as index8TQp41qL71Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/checkout/index.vue?macro=true";
import { default as Contacts1FooterRjLovXfqDwMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/contact/components/Contacts1Footer.vue?macro=true";
import { default as Contacts1HerorgugwdDY80Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/contact/components/Contacts1Hero.vue?macro=true";
import { default as DetailsGGvhIGxPR0Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/contact/components/Details.vue?macro=true";
import { default as FaqsyUK8T2UHwHMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/contact/components/Faqs.vue?macro=true";
import { default as dataTX06V4msA8Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/contact/data.ts?macro=true";
import { default as indexvbBOaL2Xf6Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/contact/index.vue?macro=true";
import { default as indexEcrgbL62wxMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/dashboard/coupons/index.vue?macro=true";
import { default as indexFPJIC6wMRxMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/dashboard/index.vue?macro=true";
import { default as inspectionsN0smp0CdPqMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/dashboard/inspections.vue?macro=true";
import { default as indexk4aJo01DPtMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/dashboard/profile/index.vue?macro=true";
import { default as transactionC1hJOJyTXNMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/dashboard/transaction.vue?macro=true";
import { default as indexB8LS981BgFMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/dashboard/user-scan-list/index.vue?macro=true";
import { default as vehiclesOfbJuYffInMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/dashboard/vehicles.vue?macro=true";
import { default as walletPXI9o1AyDxMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/dashboard/wallet.vue?macro=true";
import { default as warrantiesuDbwXiINZ3Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/dashboard/warranties.vue?macro=true";
import { default as ContentwOX9vgaVrEMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/data-products-disclaimer/components/Content.vue?macro=true";
import { default as indexpoHl8IStjrMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/data-products-disclaimer/index.vue?macro=true";
import { default as BrandsiwTMrRFnwaMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/Brands.vue?macro=true";
import { default as CarSearchBoxJe4ePSvZcdMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/CarSearchBox.vue?macro=true";
import { default as ContactzRpq0GqWRZMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/Contact.vue?macro=true";
import { default as CovidBanner5jOFyIjOjLMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/CovidBanner.vue?macro=true";
import { default as FeaturetADeOZN496Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/Feature.vue?macro=true";
import { default as FinancialFooterVwtcaUQ9EfMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/FinancialFooter.vue?macro=true";
import { default as FinancialHeroBvhewrJiOYMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/FinancialHero.vue?macro=true";
import { default as IndustrieSwiperkjK7cCzZeDMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/IndustrieSwiper.vue?macro=true";
import { default as NewsCard8OHpDaVIRGMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/NewsCard.vue?macro=true";
import { default as NewsSwipers82jhuPZFNMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/NewsSwiper.vue?macro=true";
import { default as Pricing2A3LU8iEqLMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/Pricing.vue?macro=true";
import { default as PurchaseuMK2NfBIsoMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/Purchase.vue?macro=true";
import { default as ServicesHYHWtFHk10Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/Services.vue?macro=true";
import { default as Sponsors3PbbmScpbAMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/Sponsors.vue?macro=true";
import { default as Testimonial4xjUkfNQmHMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/Testimonial.vue?macro=true";
import { default as TestimonialCardDcp0mrsMILMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/TestimonialCard.vue?macro=true";
import { default as dataKHe2iOtIZgMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/data.ts?macro=true";
import { default as index1XuUB2GLXoMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/index.vue?macro=true";
import { default as index4n6D7xUkARMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluations/[id]/index.vue?macro=true";
import { default as feedbackYhdzjm13jTMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluations/Components/feedback.vue?macro=true";
import { default as ReEvaluationp6wo6x00HrMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluations/Components/ReEvaluation.vue?macro=true";
import { default as indexuiEGbQnCDfMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluations/index.vue?macro=true";
import { default as indexPpMsslF3NVMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/fail/index.vue?macro=true";
import { default as Contacts1HerotFo62umODTMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/faq/components/Contacts1Hero.vue?macro=true";
import { default as Detailsrqr8VEp5kFMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/faq/components/Details.vue?macro=true";
import { default as Faqstacd61NQVeMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/faq/components/Faqs.vue?macro=true";
import { default as datawi8Yo4l4lpMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/faq/data.ts?macro=true";
import { default as index8aRfmsMR5KMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/faq/index.vue?macro=true";
import { default as HeroSectiongqFOOeeN3oMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/help-me-by-a-car/components/HeroSection.vue?macro=true";
import { default as SearchCarvxOvG5woZEMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/help-me-by-a-car/components/SearchCar.vue?macro=true";
import { default as Specificationsi8KGf7ZLyCMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/help-me-by-a-car/components/Specifications.vue?macro=true";
import { default as index5TSVUUNTpYMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/help-me-by-a-car/index.vue?macro=true";
import { default as Contentg3ROGYxNdNMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/impartiality-policy/components/Content.vue?macro=true";
import { default as indexPcxTN8UdtJMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/impartiality-policy/index.vue?macro=true";
import { default as indexS3RQ06wIijMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/index.vue?macro=true";
import { default as CarSearchBoxF6d05E3aSUMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/components/CarSearchBox.vue?macro=true";
import { default as ComparisonSlideryMbx3XCeJgMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/components/ComparisonSlider.vue?macro=true";
import { default as CtavYQTDTp4ucMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/components/Cta.vue?macro=true";
import { default as FeaturesPKqJxgmox7Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/components/Features.vue?macro=true";
import { default as Features2O7sb87LWxqMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/components/Features2.vue?macro=true";
import { default as InspectionTypestK8eJDdGQXMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/components/InspectionTypes.vue?macro=true";
import { default as IntegrationsJ4PUAoB2RQMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/components/Integrations.vue?macro=true";
import { default as Saas1FooterlBB49iXvnLMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/components/Saas1Footer.vue?macro=true";
import { default as Saas1Hero35ih6x1LjDMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/components/Saas1Hero.vue?macro=true";
import { default as TestimonialziJn9ClqfBMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/components/Testimonial.vue?macro=true";
import { default as TestimonialCardwPbUmfvdqMMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/components/TestimonialCard.vue?macro=true";
import { default as UseCase0tofC0iEUhMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/components/UseCase.vue?macro=true";
import { default as WorkeXe9avDZ1YMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/components/Work.vue?macro=true";
import { default as datawayF4Yq4E7Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/data.ts?macro=true";
import { default as indexdkdM0sbsnWMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/index.vue?macro=true";
import { default as indexuBpaLOIljJMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspections/[id]/index.vue?macro=true";
import { default as indexYZZl5yK6VpMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspections/index.vue?macro=true";
import { default as imagegn902fJGUIMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/assets/data/image.ts?macro=true";
import { default as BrandsHO7Mvptg3KMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/components/Brands.vue?macro=true";
import { default as CtabEMf9eGgBwMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/components/Cta.vue?macro=true";
import { default as Faqs940u7NVicYMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/components/Faqs.vue?macro=true";
import { default as Features1euqq3UH1z6Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/components/Features1.vue?macro=true";
import { default as Features2QHru5ZBw0tMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/components/Features2.vue?macro=true";
import { default as MobileApp1FooternZzhk8J1aNMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/components/MobileApp1Footer.vue?macro=true";
import { default as MobileApp1HeroHVnd8veDozMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/components/MobileApp1Hero.vue?macro=true";
import { default as ProcessSwiper18hmIS9Ek0Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/components/ProcessSwiper.vue?macro=true";
import { default as TestimonialmGYkzXm9egMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/components/Testimonial.vue?macro=true";
import { default as TestimonialCardppHZrCmv3eMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/components/TestimonialCard.vue?macro=true";
import { default as dataBAmo2loR33Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/data.ts?macro=true";
import { default as indexEx5LdvYxNDMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/index.vue?macro=true";
import { default as AnchorNavigationP8WYgIZ15CMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/ThemeComponents/AnchorNavigation.vue?macro=true";
import { default as BreadcrumbgB8RBNcfiIMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/ThemeComponents/Breadcrumb.vue?macro=true";
import { default as CustomSyntaxFz33YnHjlBMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/ThemeComponents/CustomSyntax.vue?macro=true";
import { default as JarallaxY59pDhpVhyMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/ThemeComponents/Jarallax.vue?macro=true";
import { default as LightGalleryTXolzuGc9BMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/ThemeComponents/LightGallery.vue?macro=true";
import { default as LoadingSpinnerbIResNzMEPMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/ThemeComponents/LoadingSpinner.vue?macro=true";
import { default as ParallaxvwSOZk9QZeMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/ThemeComponents/Parallax.vue?macro=true";
import { default as PasswordInputGFATPjAyZYMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/ThemeComponents/PasswordInput.vue?macro=true";
import { default as RellaxzSA5xJnsasMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/ThemeComponents/Rellax.vue?macro=true";
import { default as VanillaTilt1ItOdO8fjKMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/ThemeComponents/VanillaTilt.vue?macro=true";
import { default as VideoPopupsojHpFl9szMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/ThemeComponents/VideoPopup.vue?macro=true";
import { default as ContactkTPbmH9WrAMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/Contact.vue?macro=true";
import { default as CtaRmWdBiYeNKMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/Cta.vue?macro=true";
import { default as FeaturexLiN5jyIBUMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/Feature.vue?macro=true";
import { default as FeatureCard0SGlmeUQVnMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/FeatureCard.vue?macro=true";
import { default as MedicalFooterRp1xHWGtaOMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/MedicalFooter.vue?macro=true";
import { default as MedicalHeroDDE9OKuhLRMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/MedicalHero.vue?macro=true";
import { default as NewsVEvmLoOHHCMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/News.vue?macro=true";
import { default as NewsCardKw1fMjvXBnMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/NewsCard.vue?macro=true";
import { default as ServiceCardy8VKGcrqMxMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/ServiceCard.vue?macro=true";
import { default as ServicesDOILOGO6nNMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/Services.vue?macro=true";
import { default as TeamivMFMcZ1s1Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/Team.vue?macro=true";
import { default as TeamCardHlFcy7a0ybMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/TeamCard.vue?macro=true";
import { default as TestimoniallIHadcHdz7Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/Testimonial.vue?macro=true";
import { default as TestimonialCardMRicfk7rlyMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/TestimonialCard.vue?macro=true";
import { default as VideoReelH7EMnngeRrMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/VideoReel.vue?macro=true";
import { default as dataH5939x6Z2WMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/data.ts?macro=true";
import { default as indexuLeODlzT8GMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/index.vue?macro=true";
import { default as ArticleCardEo42ZU01XZMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/[id]/components/ArticleCard.vue?macro=true";
import { default as ArticlesSh59NqVx6EMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/[id]/components/Articles.vue?macro=true";
import { default as Commentm4Xlm7oHwBMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/[id]/components/Comment.vue?macro=true";
import { default as CommentForm4DhmqxH3gJMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/[id]/components/CommentForm.vue?macro=true";
import { default as JarallaxDW2qx9obHKMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/[id]/components/Jarallax.vue?macro=true";
import { default as LightGalleryM76fayJtJAMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/[id]/components/LightGallery.vue?macro=true";
import { default as SinglePostHeroTaqVJOEEUXMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/[id]/components/SinglePostHero.vue?macro=true";
import { default as data3mY3jVa0b3Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/[id]/data.ts?macro=true";
import { default as indexHhM4QggfQYMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/[id]/index.vue?macro=true";
import { default as BlogCardrnkMnyvB3wMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/components/BlogCard.vue?macro=true";
import { default as ContentxrjWkfCKs4Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/components/Content.vue?macro=true";
import { default as PaginationP7nTFBjggKMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/components/Pagination.vue?macro=true";
import { default as data2J5dBlRbULMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/data.ts?macro=true";
import { default as indextf1zMN3w0pMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/index.vue?macro=true";
import { default as index2FUkvHItXVMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/our-clients/index.vue?macro=true";
import { default as indexNBfAweFFUCMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/partners/index.vue?macro=true";
import { default as index6BPHezDuEDMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/payment/redirect/index.vue?macro=true";
import { default as ContentuAFj4o0055Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/privacy-policy/components/Content.vue?macro=true";
import { default as indexE6NPdmQCmUMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/privacy-policy/index.vue?macro=true";
import { default as index6GO3ydu1wSMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/sales-agent-form/index.vue?macro=true";
import { default as index2Jegg97QCnMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/success/index.vue?macro=true";
import { default as indexOOux8RCKNiMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/survey/index.vue?macro=true";
import { default as ContentB502KBtDEuMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/terms-and-condition/components/Content.vue?macro=true";
import { default as indexIL70fZoIHtMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/terms-and-condition/index.vue?macro=true";
import { default as indexltQgHaRlq7Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/trailer-inspection-and-half-trailers/index.vue?macro=true";
import { default as indexams2c22x2nMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/trim/index.vue?macro=true";
import { default as indexjSUPbkFhl2Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/vin-decoder/index.vue?macro=true";
import { default as index3U2OQo3NGLMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranties/index.vue?macro=true";
import { default as PricingktGcUeO56mMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty-form-autohub/components/Pricing.vue?macro=true";
import { default as PricingForMobile7D3adx74ejMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty-form-autohub/components/PricingForMobile.vue?macro=true";
import { default as Saas3HeroO4z1tLOSolMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty-form-autohub/components/Saas3Hero.vue?macro=true";
import { default as WarrantyForm2E06mt6ZleMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty-form-autohub/components/WarrantyForm.vue?macro=true";
import { default as indexayj4BOD0vUMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty-form-autohub/index.vue?macro=true";
import { default as indexQq2bozUPW1Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty-form/index.vue?macro=true";
import { default as CarSearchBoxdumMfqe9xQMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty/components/CarSearchBox.vue?macro=true";
import { default as Pricing3x36bmxhDvMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty/components/Pricing.vue?macro=true";
import { default as PricingForMobileK2GCG9T8NtMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty/components/PricingForMobile.vue?macro=true";
import { default as Saas3HerogzArRfiZamMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty/components/Saas3Hero.vue?macro=true";
import { default as TestimonialDfvnc8EOhxMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty/components/Testimonial.vue?macro=true";
import { default as TestimonialCardS4t6Aub0KYMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty/components/TestimonialCard.vue?macro=true";
import { default as WarrantyFeatureAISmtdSxEeMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty/components/WarrantyFeature.vue?macro=true";
import { default as WorkPgxaYUcb4EMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty/components/Work.vue?macro=true";
import { default as dataKhLQcOqWf5Meta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty/data.ts?macro=true";
import { default as indexggsb4By1WYMeta } from "C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93D3gDHTpvqaMeta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/[...slug].vue")
  },
  {
    name: "about-components-About1Hero",
    path: "/about/components/About1Hero",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/about/components/About1Hero.vue")
  },
  {
    name: "about-components-Awards",
    path: "/about/components/Awards",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/about/components/Awards.vue")
  },
  {
    name: "about-components-Benefits",
    path: "/about/components/Benefits",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/about/components/Benefits.vue")
  },
  {
    name: "about-components-Contact",
    path: "/about/components/Contact",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/about/components/Contact.vue")
  },
  {
    name: "about-components-SocialNetwork",
    path: "/about/components/SocialNetwork",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/about/components/SocialNetwork.vue")
  },
  {
    name: "about-components-Team",
    path: "/about/components/Team",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/about/components/Team.vue")
  },
  {
    name: "about-components-TeamCard",
    path: "/about/components/TeamCard",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/about/components/TeamCard.vue")
  },
  {
    name: "about-components-Testimonial",
    path: "/about/components/Testimonial",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/about/components/Testimonial.vue")
  },
  {
    name: "about-components-TestimonialCard",
    path: "/about/components/TestimonialCard",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/about/components/TestimonialCard.vue")
  },
  {
    name: "about-components-VideoReel",
    path: "/about/components/VideoReel",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/about/components/VideoReel.vue")
  },
  {
    name: "about-data",
    path: "/about/data",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/about/data.ts")
  },
  {
    name: "about",
    path: "/about",
    meta: indexjj0nnwtJvrMeta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/about/index.vue")
  },
  {
    name: "branches",
    path: "/branches",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/branches/index.vue")
  },
  {
    name: "cancel",
    path: "/cancel",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/cancel/index.vue")
  },
  {
    name: "career",
    path: "/career",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/career/index.vue")
  },
  {
    name: "cartrust-agent-components-Faqs",
    path: "/cartrust-agent/components/Faqs",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/cartrust-agent/components/Faqs.vue")
  },
  {
    name: "cartrust-agent-components-Features",
    path: "/cartrust-agent/components/Features",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/cartrust-agent/components/Features.vue")
  },
  {
    name: "cartrust-agent-components-Opportunities",
    path: "/cartrust-agent/components/Opportunities",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/cartrust-agent/components/Opportunities.vue")
  },
  {
    name: "cartrust-agent-components-PlanComparison",
    path: "/cartrust-agent/components/PlanComparison",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/cartrust-agent/components/PlanComparison.vue")
  },
  {
    name: "cartrust-agent-components-Pricing",
    path: "/cartrust-agent/components/Pricing",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/cartrust-agent/components/Pricing.vue")
  },
  {
    name: "cartrust-agent-components-Saas3Hero",
    path: "/cartrust-agent/components/Saas3Hero",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/cartrust-agent/components/Saas3Hero.vue")
  },
  {
    name: "cartrust-agent-components-Testimonial",
    path: "/cartrust-agent/components/Testimonial",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/cartrust-agent/components/Testimonial.vue")
  },
  {
    name: "cartrust-agent-components-TestimonialCard",
    path: "/cartrust-agent/components/TestimonialCard",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/cartrust-agent/components/TestimonialCard.vue")
  },
  {
    name: "cartrust-agent-components-Work",
    path: "/cartrust-agent/components/Work",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/cartrust-agent/components/Work.vue")
  },
  {
    name: "cartrust-agent-data",
    path: "/cartrust-agent/data",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/cartrust-agent/data.ts")
  },
  {
    name: "cartrust-agent",
    path: "/cartrust-agent",
    meta: indexaUJbwO61H2Meta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/cartrust-agent/index.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/checkout/index.vue")
  },
  {
    name: "contact-components-Contacts1Footer",
    path: "/contact/components/Contacts1Footer",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/contact/components/Contacts1Footer.vue")
  },
  {
    name: "contact-components-Contacts1Hero",
    path: "/contact/components/Contacts1Hero",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/contact/components/Contacts1Hero.vue")
  },
  {
    name: "contact-components-Details",
    path: "/contact/components/Details",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/contact/components/Details.vue")
  },
  {
    name: "contact-components-Faqs",
    path: "/contact/components/Faqs",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/contact/components/Faqs.vue")
  },
  {
    name: "contact-data",
    path: "/contact/data",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/contact/data.ts")
  },
  {
    name: "contact",
    path: "/contact",
    meta: indexvbBOaL2Xf6Meta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/contact/index.vue")
  },
  {
    name: "dashboard-coupons",
    path: "/dashboard/coupons",
    meta: indexEcrgbL62wxMeta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/dashboard/coupons/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexFPJIC6wMRxMeta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-inspections",
    path: "/dashboard/inspections",
    meta: inspectionsN0smp0CdPqMeta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/dashboard/inspections.vue")
  },
  {
    name: "dashboard-profile",
    path: "/dashboard/profile",
    meta: indexk4aJo01DPtMeta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/dashboard/profile/index.vue")
  },
  {
    name: "dashboard-transaction",
    path: "/dashboard/transaction",
    meta: transactionC1hJOJyTXNMeta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/dashboard/transaction.vue")
  },
  {
    name: "dashboard-user-scan-list",
    path: "/dashboard/user-scan-list",
    meta: indexB8LS981BgFMeta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/dashboard/user-scan-list/index.vue")
  },
  {
    name: "dashboard-vehicles",
    path: "/dashboard/vehicles",
    meta: vehiclesOfbJuYffInMeta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/dashboard/vehicles.vue")
  },
  {
    name: "dashboard-wallet",
    path: "/dashboard/wallet",
    meta: walletPXI9o1AyDxMeta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/dashboard/wallet.vue")
  },
  {
    name: "dashboard-warranties",
    path: "/dashboard/warranties",
    meta: warrantiesuDbwXiINZ3Meta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/dashboard/warranties.vue")
  },
  {
    name: "data-products-disclaimer-components-Content",
    path: "/data-products-disclaimer/components/Content",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/data-products-disclaimer/components/Content.vue")
  },
  {
    name: "data-products-disclaimer",
    path: "/data-products-disclaimer",
    meta: indexpoHl8IStjrMeta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/data-products-disclaimer/index.vue")
  },
  {
    name: "evaluation-components-Brands",
    path: "/evaluation/components/Brands",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/Brands.vue")
  },
  {
    name: "evaluation-components-CarSearchBox",
    path: "/evaluation/components/CarSearchBox",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/CarSearchBox.vue")
  },
  {
    name: "evaluation-components-Contact",
    path: "/evaluation/components/Contact",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/Contact.vue")
  },
  {
    name: "evaluation-components-CovidBanner",
    path: "/evaluation/components/CovidBanner",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/CovidBanner.vue")
  },
  {
    name: "evaluation-components-Feature",
    path: "/evaluation/components/Feature",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/Feature.vue")
  },
  {
    name: "evaluation-components-FinancialFooter",
    path: "/evaluation/components/FinancialFooter",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/FinancialFooter.vue")
  },
  {
    name: "evaluation-components-FinancialHero",
    path: "/evaluation/components/FinancialHero",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/FinancialHero.vue")
  },
  {
    name: "evaluation-components-IndustrieSwiper",
    path: "/evaluation/components/IndustrieSwiper",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/IndustrieSwiper.vue")
  },
  {
    name: "evaluation-components-NewsCard",
    path: "/evaluation/components/NewsCard",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/NewsCard.vue")
  },
  {
    name: "evaluation-components-NewsSwiper",
    path: "/evaluation/components/NewsSwiper",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/NewsSwiper.vue")
  },
  {
    name: "evaluation-components-Pricing",
    path: "/evaluation/components/Pricing",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/Pricing.vue")
  },
  {
    name: "evaluation-components-Purchase",
    path: "/evaluation/components/Purchase",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/Purchase.vue")
  },
  {
    name: "evaluation-components-Services",
    path: "/evaluation/components/Services",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/Services.vue")
  },
  {
    name: "evaluation-components-Sponsors",
    path: "/evaluation/components/Sponsors",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/Sponsors.vue")
  },
  {
    name: "evaluation-components-Testimonial",
    path: "/evaluation/components/Testimonial",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/Testimonial.vue")
  },
  {
    name: "evaluation-components-TestimonialCard",
    path: "/evaluation/components/TestimonialCard",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/components/TestimonialCard.vue")
  },
  {
    name: "evaluation-data",
    path: "/evaluation/data",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/data.ts")
  },
  {
    name: "evaluation",
    path: "/evaluation",
    meta: index1XuUB2GLXoMeta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluation/index.vue")
  },
  {
    name: "evaluations-id",
    path: "/evaluations/:id()",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluations/[id]/index.vue")
  },
  {
    name: "evaluations-Components-feedback",
    path: "/evaluations/Components/feedback",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluations/Components/feedback.vue")
  },
  {
    name: "evaluations-Components-ReEvaluation",
    path: "/evaluations/Components/ReEvaluation",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluations/Components/ReEvaluation.vue")
  },
  {
    name: "evaluations",
    path: "/evaluations",
    meta: indexuiEGbQnCDfMeta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/evaluations/index.vue")
  },
  {
    name: "fail",
    path: "/fail",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/fail/index.vue")
  },
  {
    name: "faq-components-Contacts1Hero",
    path: "/faq/components/Contacts1Hero",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/faq/components/Contacts1Hero.vue")
  },
  {
    name: "faq-components-Details",
    path: "/faq/components/Details",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/faq/components/Details.vue")
  },
  {
    name: "faq-components-Faqs",
    path: "/faq/components/Faqs",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/faq/components/Faqs.vue")
  },
  {
    name: "faq-data",
    path: "/faq/data",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/faq/data.ts")
  },
  {
    name: "faq",
    path: "/faq",
    meta: index8aRfmsMR5KMeta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/faq/index.vue")
  },
  {
    name: "help-me-by-a-car-components-HeroSection",
    path: "/help-me-by-a-car/components/HeroSection",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/help-me-by-a-car/components/HeroSection.vue")
  },
  {
    name: "help-me-by-a-car-components-SearchCar",
    path: "/help-me-by-a-car/components/SearchCar",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/help-me-by-a-car/components/SearchCar.vue")
  },
  {
    name: "help-me-by-a-car-components-Specifications",
    path: "/help-me-by-a-car/components/Specifications",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/help-me-by-a-car/components/Specifications.vue")
  },
  {
    name: "help-me-by-a-car",
    path: "/help-me-by-a-car",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/help-me-by-a-car/index.vue")
  },
  {
    name: "impartiality-policy-components-Content",
    path: "/impartiality-policy/components/Content",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/impartiality-policy/components/Content.vue")
  },
  {
    name: "impartiality-policy",
    path: "/impartiality-policy",
    meta: indexPcxTN8UdtJMeta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/impartiality-policy/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/index.vue")
  },
  {
    name: "inspection-components-CarSearchBox",
    path: "/inspection/components/CarSearchBox",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/components/CarSearchBox.vue")
  },
  {
    name: "inspection-components-ComparisonSlider",
    path: "/inspection/components/ComparisonSlider",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/components/ComparisonSlider.vue")
  },
  {
    name: "inspection-components-Cta",
    path: "/inspection/components/Cta",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/components/Cta.vue")
  },
  {
    name: "inspection-components-Features",
    path: "/inspection/components/Features",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/components/Features.vue")
  },
  {
    name: "inspection-components-Features2",
    path: "/inspection/components/Features2",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/components/Features2.vue")
  },
  {
    name: "inspection-components-InspectionTypes",
    path: "/inspection/components/InspectionTypes",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/components/InspectionTypes.vue")
  },
  {
    name: "inspection-components-Integrations",
    path: "/inspection/components/Integrations",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/components/Integrations.vue")
  },
  {
    name: "inspection-components-Saas1Footer",
    path: "/inspection/components/Saas1Footer",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/components/Saas1Footer.vue")
  },
  {
    name: "inspection-components-Saas1Hero",
    path: "/inspection/components/Saas1Hero",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/components/Saas1Hero.vue")
  },
  {
    name: "inspection-components-Testimonial",
    path: "/inspection/components/Testimonial",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/components/Testimonial.vue")
  },
  {
    name: "inspection-components-TestimonialCard",
    path: "/inspection/components/TestimonialCard",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/components/TestimonialCard.vue")
  },
  {
    name: "inspection-components-UseCase",
    path: "/inspection/components/UseCase",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/components/UseCase.vue")
  },
  {
    name: "inspection-components-Work",
    path: "/inspection/components/Work",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/components/Work.vue")
  },
  {
    name: "inspection-data",
    path: "/inspection/data",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/data.ts")
  },
  {
    name: "inspection",
    path: "/inspection",
    meta: indexdkdM0sbsnWMeta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspection/index.vue")
  },
  {
    name: "inspections-id",
    path: "/inspections/:id()",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspections/[id]/index.vue")
  },
  {
    name: "inspections",
    path: "/inspections",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/inspections/index.vue")
  },
  {
    name: "landing-mobile-app-1-assets-data-image",
    path: "/landing/mobile-app-1/assets/data/image",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/assets/data/image.ts")
  },
  {
    name: "landing-mobile-app-1-components-Brands",
    path: "/landing/mobile-app-1/components/Brands",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/components/Brands.vue")
  },
  {
    name: "landing-mobile-app-1-components-Cta",
    path: "/landing/mobile-app-1/components/Cta",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/components/Cta.vue")
  },
  {
    name: "landing-mobile-app-1-components-Faqs",
    path: "/landing/mobile-app-1/components/Faqs",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/components/Faqs.vue")
  },
  {
    name: "landing-mobile-app-1-components-Features1",
    path: "/landing/mobile-app-1/components/Features1",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/components/Features1.vue")
  },
  {
    name: "landing-mobile-app-1-components-Features2",
    path: "/landing/mobile-app-1/components/Features2",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/components/Features2.vue")
  },
  {
    name: "landing-mobile-app-1-components-MobileApp1Footer",
    path: "/landing/mobile-app-1/components/MobileApp1Footer",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/components/MobileApp1Footer.vue")
  },
  {
    name: "landing-mobile-app-1-components-MobileApp1Hero",
    path: "/landing/mobile-app-1/components/MobileApp1Hero",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/components/MobileApp1Hero.vue")
  },
  {
    name: "landing-mobile-app-1-components-ProcessSwiper",
    path: "/landing/mobile-app-1/components/ProcessSwiper",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/components/ProcessSwiper.vue")
  },
  {
    name: "landing-mobile-app-1-components-Testimonial",
    path: "/landing/mobile-app-1/components/Testimonial",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/components/Testimonial.vue")
  },
  {
    name: "landing-mobile-app-1-components-TestimonialCard",
    path: "/landing/mobile-app-1/components/TestimonialCard",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/components/TestimonialCard.vue")
  },
  {
    name: "landing-mobile-app-1-data",
    path: "/landing/mobile-app-1/data",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/data.ts")
  },
  {
    name: "landing-mobile-app-1",
    path: "/landing/mobile-app-1",
    meta: indexEx5LdvYxNDMeta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/index.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-AnchorNavigation",
    path: "/landing/mobile-app-1/ThemeComponents/AnchorNavigation",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/ThemeComponents/AnchorNavigation.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-Breadcrumb",
    path: "/landing/mobile-app-1/ThemeComponents/Breadcrumb",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/ThemeComponents/Breadcrumb.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-CustomSyntax",
    path: "/landing/mobile-app-1/ThemeComponents/CustomSyntax",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/ThemeComponents/CustomSyntax.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-Jarallax",
    path: "/landing/mobile-app-1/ThemeComponents/Jarallax",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/ThemeComponents/Jarallax.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-LightGallery",
    path: "/landing/mobile-app-1/ThemeComponents/LightGallery",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/ThemeComponents/LightGallery.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-LoadingSpinner",
    path: "/landing/mobile-app-1/ThemeComponents/LoadingSpinner",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/ThemeComponents/LoadingSpinner.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-Parallax",
    path: "/landing/mobile-app-1/ThemeComponents/Parallax",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/ThemeComponents/Parallax.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-PasswordInput",
    path: "/landing/mobile-app-1/ThemeComponents/PasswordInput",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/ThemeComponents/PasswordInput.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-Rellax",
    path: "/landing/mobile-app-1/ThemeComponents/Rellax",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/ThemeComponents/Rellax.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-VanillaTilt",
    path: "/landing/mobile-app-1/ThemeComponents/VanillaTilt",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/ThemeComponents/VanillaTilt.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-VideoPopup",
    path: "/landing/mobile-app-1/ThemeComponents/VideoPopup",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/landing/mobile-app-1/ThemeComponents/VideoPopup.vue")
  },
  {
    name: "medical-components-Contact",
    path: "/medical/components/Contact",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/Contact.vue")
  },
  {
    name: "medical-components-Cta",
    path: "/medical/components/Cta",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/Cta.vue")
  },
  {
    name: "medical-components-Feature",
    path: "/medical/components/Feature",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/Feature.vue")
  },
  {
    name: "medical-components-FeatureCard",
    path: "/medical/components/FeatureCard",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/FeatureCard.vue")
  },
  {
    name: "medical-components-MedicalFooter",
    path: "/medical/components/MedicalFooter",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/MedicalFooter.vue")
  },
  {
    name: "medical-components-MedicalHero",
    path: "/medical/components/MedicalHero",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/MedicalHero.vue")
  },
  {
    name: "medical-components-News",
    path: "/medical/components/News",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/News.vue")
  },
  {
    name: "medical-components-NewsCard",
    path: "/medical/components/NewsCard",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/NewsCard.vue")
  },
  {
    name: "medical-components-ServiceCard",
    path: "/medical/components/ServiceCard",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/ServiceCard.vue")
  },
  {
    name: "medical-components-Services",
    path: "/medical/components/Services",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/Services.vue")
  },
  {
    name: "medical-components-Team",
    path: "/medical/components/Team",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/Team.vue")
  },
  {
    name: "medical-components-TeamCard",
    path: "/medical/components/TeamCard",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/TeamCard.vue")
  },
  {
    name: "medical-components-Testimonial",
    path: "/medical/components/Testimonial",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/Testimonial.vue")
  },
  {
    name: "medical-components-TestimonialCard",
    path: "/medical/components/TestimonialCard",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/TestimonialCard.vue")
  },
  {
    name: "medical-components-VideoReel",
    path: "/medical/components/VideoReel",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/components/VideoReel.vue")
  },
  {
    name: "medical-data",
    path: "/medical/data",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/data.ts")
  },
  {
    name: "medical",
    path: "/medical",
    meta: indexuLeODlzT8GMeta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/medical/index.vue")
  },
  {
    name: "news-id-components-ArticleCard",
    path: "/news/:id()/components/ArticleCard",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/[id]/components/ArticleCard.vue")
  },
  {
    name: "news-id-components-Articles",
    path: "/news/:id()/components/Articles",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/[id]/components/Articles.vue")
  },
  {
    name: "news-id-components-Comment",
    path: "/news/:id()/components/Comment",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/[id]/components/Comment.vue")
  },
  {
    name: "news-id-components-CommentForm",
    path: "/news/:id()/components/CommentForm",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/[id]/components/CommentForm.vue")
  },
  {
    name: "news-id-components-Jarallax",
    path: "/news/:id()/components/Jarallax",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/[id]/components/Jarallax.vue")
  },
  {
    name: "news-id-components-LightGallery",
    path: "/news/:id()/components/LightGallery",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/[id]/components/LightGallery.vue")
  },
  {
    name: "news-id-components-SinglePostHero",
    path: "/news/:id()/components/SinglePostHero",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/[id]/components/SinglePostHero.vue")
  },
  {
    name: "news-id-data",
    path: "/news/:id()/data",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/[id]/data.ts")
  },
  {
    name: "news-id",
    path: "/news/:id()",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/[id]/index.vue")
  },
  {
    name: "news-components-BlogCard",
    path: "/news/components/BlogCard",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/components/BlogCard.vue")
  },
  {
    name: "news-components-Content",
    path: "/news/components/Content",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/components/Content.vue")
  },
  {
    name: "news-components-Pagination",
    path: "/news/components/Pagination",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/components/Pagination.vue")
  },
  {
    name: "news-data",
    path: "/news/data",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/data.ts")
  },
  {
    name: "news",
    path: "/news",
    meta: indextf1zMN3w0pMeta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/news/index.vue")
  },
  {
    name: "our-clients",
    path: "/our-clients",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/our-clients/index.vue")
  },
  {
    name: "partners",
    path: "/partners",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/partners/index.vue")
  },
  {
    name: "payment-redirect",
    path: "/payment/redirect",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/payment/redirect/index.vue")
  },
  {
    name: "privacy-policy-components-Content",
    path: "/privacy-policy/components/Content",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/privacy-policy/components/Content.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: indexE6NPdmQCmUMeta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/privacy-policy/index.vue")
  },
  {
    name: "sales-agent-form",
    path: "/sales-agent-form",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/sales-agent-form/index.vue")
  },
  {
    name: "success",
    path: "/success",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/success/index.vue")
  },
  {
    name: "survey",
    path: "/survey",
    meta: indexOOux8RCKNiMeta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/survey/index.vue")
  },
  {
    name: "terms-and-condition-components-Content",
    path: "/terms-and-condition/components/Content",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/terms-and-condition/components/Content.vue")
  },
  {
    name: "terms-and-condition",
    path: "/terms-and-condition",
    meta: indexIL70fZoIHtMeta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/terms-and-condition/index.vue")
  },
  {
    name: "trailer-inspection-and-half-trailers",
    path: "/trailer-inspection-and-half-trailers",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/trailer-inspection-and-half-trailers/index.vue")
  },
  {
    name: "trim",
    path: "/trim",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/trim/index.vue")
  },
  {
    name: "vin-decoder",
    path: "/vin-decoder",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/vin-decoder/index.vue")
  },
  {
    name: "warranties",
    path: "/warranties",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranties/index.vue")
  },
  {
    name: "warranty-form-autohub-components-Pricing",
    path: "/warranty-form-autohub/components/Pricing",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty-form-autohub/components/Pricing.vue")
  },
  {
    name: "warranty-form-autohub-components-PricingForMobile",
    path: "/warranty-form-autohub/components/PricingForMobile",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty-form-autohub/components/PricingForMobile.vue")
  },
  {
    name: "warranty-form-autohub-components-Saas3Hero",
    path: "/warranty-form-autohub/components/Saas3Hero",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty-form-autohub/components/Saas3Hero.vue")
  },
  {
    name: "warranty-form-autohub-components-WarrantyForm",
    path: "/warranty-form-autohub/components/WarrantyForm",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty-form-autohub/components/WarrantyForm.vue")
  },
  {
    name: "warranty-form-autohub",
    path: "/warranty-form-autohub",
    meta: indexayj4BOD0vUMeta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty-form-autohub/index.vue")
  },
  {
    name: "warranty-form",
    path: "/warranty-form",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty-form/index.vue")
  },
  {
    name: "warranty-components-CarSearchBox",
    path: "/warranty/components/CarSearchBox",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty/components/CarSearchBox.vue")
  },
  {
    name: "warranty-components-Pricing",
    path: "/warranty/components/Pricing",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty/components/Pricing.vue")
  },
  {
    name: "warranty-components-PricingForMobile",
    path: "/warranty/components/PricingForMobile",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty/components/PricingForMobile.vue")
  },
  {
    name: "warranty-components-Saas3Hero",
    path: "/warranty/components/Saas3Hero",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty/components/Saas3Hero.vue")
  },
  {
    name: "warranty-components-Testimonial",
    path: "/warranty/components/Testimonial",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty/components/Testimonial.vue")
  },
  {
    name: "warranty-components-TestimonialCard",
    path: "/warranty/components/TestimonialCard",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty/components/TestimonialCard.vue")
  },
  {
    name: "warranty-components-WarrantyFeature",
    path: "/warranty/components/WarrantyFeature",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty/components/WarrantyFeature.vue")
  },
  {
    name: "warranty-components-Work",
    path: "/warranty/components/Work",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty/components/Work.vue")
  },
  {
    name: "warranty-data",
    path: "/warranty/data",
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty/data.ts")
  },
  {
    name: "warranty",
    path: "/warranty",
    meta: indexggsb4By1WYMeta || {},
    component: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/pages/warranty/index.vue")
  }
]