<script lang="ts" setup>
const props = withDefaults(defineProps<{ modelValue: string; label?: string; disabled?: boolean }>(), {
  label: 'رقم الجوال',
  disabled: false,
})

const emit = defineEmits<{
  'update:modelValue': [payload: string]
}>()

const isTouched = ref(false)

const phoneError = computed(() => {
  if (!isTouched.value) return false

  if (!props.modelValue.startsWith('05') && !props.modelValue.startsWith('5')) return 'must start with 05 or 5'

  if (props.modelValue.startsWith('05') && props.modelValue.length !== 10) return 'number must be exactly 10 digits'

  if (props.modelValue.startsWith('5') && props.modelValue.length !== 9) return 'number must be exactly 9 digits'

  return false
})

const maxLength = computed(() => {
  return props.modelValue.startsWith('05') ? 10 : 9
})

const onInput = (e: Event) => {
  isTouched.value = true
  const input = e.target as HTMLInputElement

  if (input.value.length > maxLength.value) {
    emit('update:modelValue', input.value.slice(0, maxLength.value))
  } else {
    emit('update:modelValue', input.value)
  }
}
</script>

<template>
  <div>
    <label for="phone_input" class="fs-14 fw-700 textPrimary mb-2 d-block">
      <slot name="label">
        {{ $t('mobile_number') }}
      </slot>
    </label>
    <div class="app-input position-relative">
      <input
        id="phone_input"
        :value="modelValue"
        @input="onInput"
        :disabled="disabled"
        autofocus
        type="number"
        inputmode="tel"
        :placeholder="$t('enter_mobile_number')"
        class="fs-16"
        :class="{ phoneError }"
      />
      <div class="flags d-flex align-items-center gap-1">
        <div class="fs-14 fw-400">|</div>
        <div class="fs-14 fw-400">+966</div>
        <img src="assets/images/uk.svg" loading="lazy" alt="uk flag" />
      </div>
    </div>
    <p class="text-danger text-xs" v-if="phoneError">{{ phoneError }}</p>
  </div>
</template>

<style lang="css" scoped>
.app-input input {
  width: 100%;
  height: 43px;
  border: 1px solid #e0e0e0;
  background: transparent;
  outline: none;
  padding: 0 15px;
  border-radius: 5px;
  padding-left: 100px;
}

.app-input input.phoneError {
  border: 2px solid red;
}

input:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.app-input .flags {
  position: absolute;
  left: 15px;
  top: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
