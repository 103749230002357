import validate from "C:/Users/LENOVO/Downloads/Cartrust/website/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "C:/Users/LENOVO/Downloads/Cartrust/website/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("C:/Users/LENOVO/Downloads/Cartrust/website/middleware/auth.ts"),
  "language-service": () => import("C:/Users/LENOVO/Downloads/Cartrust/website/middleware/languageService.ts"),
  "tamara-service": () => import("C:/Users/LENOVO/Downloads/Cartrust/website/middleware/tamaraService.ts")
}